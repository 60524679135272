const categoryLabels: Record<string, string> = {
  'muebles-y-decoracion': 'Muebles y decoración',
  'salas-y-family-room': 'Salas y family room',
  'colchones-y-boxes': 'Colchones y boxes',
  'recamaras': 'Recámaras',
  'banos': 'Baño',
  'decoracion': 'Decoración',
  'limpieza-y-organizacion': 'Limpieza y organización',
  'blancos': 'Blancos',
  'iluminacion': 'Iluminación',
  'electrodomesticos-y-linea-blanca': 'Electrodomésticos y línea blanca',
  'electrodomesticos': 'Electrodomésticos',
  'para-cocinar': 'Para cocinar',
  'aspiradoras': 'Aspiradoras',
  'hidrolavadoras-y-herramientas': 'Hidrolavadoras y herramientas',
  'estudio-y-oficina': 'Estudio y oficina',
  'libros-y-revistas': 'Libros y Revistas',
  'mascotas': 'Mascotas',
  'perro': 'Perro',
  'patio-y-jardin': 'Patio y jardín',
  'muebles-para-jardin': 'Muebles para jardín',
  'carpas': 'Carpas',
  'decoracion-al-aire-libre': 'Decoración al aire libre',
  'jardineria': 'Jardineria',
  'construccion-y-ferreteria': 'Construcción y ferretería',
  'electricidad': 'Electricidad',
  'herramientas': 'Herramientas',
  'plomeria': 'Plomería',
  'carpinteria': 'Carpintería',
  'construccion': 'Construcción',
  'deporte-y-estilo-de-vida': 'Deporte y estilo de vida',
  'aparatos-de-ejercicio': 'Aparatos de ejercicio',
  'equipaje': 'Equipaje',
  'juegos-y-juguetes': 'Juegos y juguetes',
  'brincolines-albercas-y-plazas': 'Brincolines, albercas y plazas',
  'juguetes': 'Juguetes',
  'juegos-de-mesa': 'Juegos de mesa',
  'maternidad-y-bebe': 'Maternidad y bebé',
  'hora-del-bano': 'Hora del baño',
  'hora-de-dormir': 'Hora de dormir',
};

const categoryTree: Record<string, string[]> = {
  'muebles-y-decoracion': [
    'salas-y-family-room',
    'colchones-y-boxes',
    'recamaras',
    'banos',
    'decoracion',
    'limpieza-y-organizacion',
    'blancos',
    'iluminacion',
  ],
  'electrodomesticos-y-linea-blanca': [
    'electrodomesticos',
    'para-cocinar',
    'aspiradoras',
    'hidrolavadoras-y-herramientas',
  ],
  'estudio-y-oficina': [
    'libros-y-revistas',
  ],
  'mascotas': [
    'perro',
  ],
  'patio-y-jardin': [
    'muebles-para-jardin',
    'carpas',
    'decoracion-al-aire-libre',
    'jardineria',
  ],
  'construccion-y-ferreteria': [
    'electricidad',
    'herramientas',
    'plomeria',
    'carpinteria',
    'construccion',
  ],
  'deporte-y-estilo-de-vida': [
    'aparatos-de-ejercicio',
    'equipaje',
  ],
  'juegos-y-juguetes': [
    'brincolines-albercas-y-plazas',
    'juguetes',
    'juegos-de-mesa',
  ],
  'maternidad-y-bebe': [
    'hora-del-bano',
    'hora-de-dormir',
  ],
};

const createInvoiceErrorCausesToDescriptions: Record<string, string> = {
  'rfc-inactive-in-sat': 'RFC inactivo en la lista de RFC del SAT.',
  'name-inactive-in-sat': 'Nombre inactivo en la lista de RFC del SAT.',
  'name-not-associated-with-rfc': 'Nombre no pertenece al RFC registrado.',
  'tax-domicile-not-found-in-sat': 'Domicilio fiscal no se encuentra en la lista de RFC del SAT.',
  'tax-domicile-not-associated-with-registered-rfc': 'Domicilio fiscal no pertecene al RFC registrado.',
  'tax-regime-not-related-to-type-of-person': 'Régimen Fiscal no corresponde al tipo de persona.', // TODO: This error won't happen if we filter the tax regime based on type of person
  'tax-regime-not-related-to-rfc': 'Régimen Fiscal no corresponde al RFC.',
  'cfdi-use-not-related-to-type-of-person': 'Uso del CFDI debe corresponder con el tipo de persona y el régimen fiscal conforme al catálogo del SAT.', // TODO: This error won't happen if we filter the CFDI use based on type of person and tax regime
};

export const constants = {
  appVersionCode: 38,
  cache: {
    expireThreshold: 3 * 60 * 1000, // 3 minutes
    uniqueLabels: ['favorites', 'shopping-cart'], // Entries on cache with these labels will be unique
    whiteListLabels: ['favorites', 'highlights', 'opportunities', 'shopping-cart'], // Entries on cache with these labels will be saved on localStorage
  },
  consumerCreditDiscountBenefit: {
    requestedCreditDateSubtraction: 90, // days
    threshold: 0.7,
  },
  defaultCurrency: 'MXN',
  ecommerce: {
    categories: {
      labels: categoryLabels,
      tree: categoryTree,
    },
    invoices: {
      createInvoiceErrorCausesToDescriptions,
      deadlineSendingInvoice: 5, // in days
    }
  },
  maxFavoriteItems: 16,
  maxShoppingCartItems: 16,
  maxTrackedProduct: 16,
  mortgageSimulator: {
    loanPercentage: {
      maximum: 0.9,
      minimum: 0.1,
    },
    propertyValue: {
      maximum: 999999999,
      minimum: 250000,
    }
  },
  paymentOptions: ['debit', 'credit', 'credit-36', 'credit-24', 'credit-18', 'credit-12', 'credit-6', 'credit-3'] as const, // TODO: Don't change the order, we should refactor some logic first
  postalCodeLength: 5, // TODO F&F: remove or add/define all the country specific validations here
  pricing: {
    ecommerce: {
      superSale: 0.5,
      sale: 0.35,
    }
  },
  shipping: {
    priority: {
      delay: 2,
      time: 5,
    },
    regular: {
      delay: 5,
      time: 10,
    },
  },
  remodeling: {
    types: [
      { label: 'Baño', value: 'bathroom' },
      { label: 'Cocina', value: 'kitchen' },
      { label: 'Remodelación integral', value: 'fullhouse' },
      { label: 'Otro', value: 'others' },
    ]
  },
  tax: 0.16,
  warehouse: {
    aboutToExpireThreshold: 7, // days
  }
};
