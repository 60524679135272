import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { subYears } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { namePattern, phoneNumberPattern } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { SeoService } from '../../../services/seo/seo.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';
import { isLowerCaseWord, isUpperCaseWord, toCapitalizeCase } from '../../../utils/string.utils';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [
    ButtonComponent,
    CalendarModule,
    CommonModule,
    InputMaskModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './onboarding.page.html',
  styleUrl: './onboarding.page.scss',
})
export class OnboardingPage implements OnInit {
  form: FormGroup<{
    birthDate: FormControl<Date | null>
    fatherLastName: FormControl<string | null>,
    motherLastName: FormControl<string | null>,
    name: FormControl<string | null>,
    phoneNumber: FormControl<string |  null>,
  }>;
  maxDateBirthDate = subYears(new Date(), 18);
  saving = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private movingOrdersService: MovingOrdersService,
    private router: Router,
    private seoService: SeoService,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.form = new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      fatherLastName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      motherLastName: new FormControl<string | null>(null, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(phoneNumberPattern)]),
      birthDate: new FormControl<Date | null>(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    await this.setValues();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  async save(): Promise<void> {
    let step = '';
    try {
      this.saving = true;
      step = 'update-user';
      await this.usersService.updateUser({
        name: this.form.controls.name.value!,
        fatherLastName: this.form.controls.fatherLastName.value!,
        motherLastName: this.form.controls.motherLastName.value || null,
        birthDate: this.form.controls.birthDate.value!,
        phoneNumber: `+${this.form.controls.phoneNumber.value}`,
      });
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser?.profileDataCompleted), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(
        user,
        this.dataStorageService,
        this.router,
        this.activatedRoute.snapshot.queryParamMap,
        this.analyticsService,
        this.movingOrdersService,
        this.warehouseOrdersService,
      );
      this.analyticsService.logUserCreationEvent({ step: 'user-profile-data', method: user?.provider });
    } catch (error) {
      this.errorReportingService.log('OnboardingPage.save()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
    } finally {
      this.saving = false;
    }
  }
  // TODO: move this pattern to an utils.
  private cleanString(value: string): string {
    return value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑçÇåÅæÆøØ'\s-]/g, '');
  }

  private async setValues(): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const nameInClaims = session.claims['displayName'] ?? session.claims['name'];
      if (typeof nameInClaims === 'string') {
        const names = nameInClaims.split(' ').map(this.cleanString);
        let name = names[0];
        let fatherLastName = names.length >= 2 ? names[1] : '';
        let motherLastName = names.length >= 3 ? names[2] : '';
        name = isLowerCaseWord(name) || isUpperCaseWord(name) ? toCapitalizeCase(name) : name;
        fatherLastName = isLowerCaseWord(fatherLastName) || isUpperCaseWord(fatherLastName) ? toCapitalizeCase(fatherLastName) : fatherLastName;
        motherLastName = isLowerCaseWord(motherLastName) || isUpperCaseWord(motherLastName) ? toCapitalizeCase(motherLastName) : motherLastName;
        if (name) {
          this.form.controls.name.setValue(name);
          this.form.controls.name.markAsDirty();
        }
        if (fatherLastName) {
          this.form.controls.fatherLastName.setValue(fatherLastName);
          this.form.controls.fatherLastName.markAsDirty();
        }
        if (motherLastName) {
          this.form.controls.motherLastName.setValue(motherLastName);
          this.form.controls.motherLastName.markAsDirty();
        }
      }
    }
  }
}
