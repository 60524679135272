import { WarehouseOrder } from '@homein-hogar-server';
import * as faker from 'faker';
import { USERS_MOCK } from '../users/users.mock';
import { constants } from '../../constants';

const user = USERS_MOCK[0];
const aboutToExpireThreshold = constants.warehouse.aboutToExpireThreshold * 24 * 60 * 60 * 1000; // milliseconds

export const WAREHOUSE_ORDERS_MOCK: WarehouseOrder[] = [
  {
    additionalData: {
      chatUrl: faker.internet.url(),
      hasTransportation: true,
      originalStatus: 'paid',
      partnerOrderId: faker.datatype.number({ min: 6, max: 6 }),
      quoteUrl: faker.internet.url(),
      reservationPrice: 199,
      volume: 2,
    },
    clientAddress: 'Av. 22 de Febrero 82, Centro de Azcapotzalco, Azcapotzalco, 02000 Ciudad de México, CDMX, México',
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    finishAt: new Date((new Date()).getTime() + aboutToExpireThreshold + 86400000),
    id: 'JpE4OXbfKfCtNpilcppa',
    paymentIntentId: 'KhcATKPIS5IlbXAXb6pW',
    startAt: new Date(),
    status: 'done',
    subtotal: 199,
    tax: 27.45,
    totalAmount: 199,
    updatedAt: new Date(),
    userId: user.id,
    warehouseAddress: 'Mexico City, CDMX, Mexico',
  },
  {
    additionalData: {
      chatUrl: faker.internet.url(),
      hasTransportation: false,
      originalStatus: 'paid',
      partnerOrderId: faker.datatype.number({ min: 6, max: 6 }),
      quoteUrl: faker.internet.url(),
      reservationPrice: 199,
      volume: 2,
    },
    clientAddress: 'Av. 22 de Febrero 82, Centro de Azcapotzalco, Azcapotzalco, 02000 Ciudad de México, CDMX, México',
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    finishAt: new Date((new Date()).getTime() + aboutToExpireThreshold - 86400000),
    id: 'pl8YAncZPzYZfnKHMgVc',
    paymentIntentId: 'f3lXSUOcOq4JnkqTNMLn',
    startAt: new Date(),
    status: 'done',
    subtotal: 199,
    tax: 27.45,
    totalAmount: 199,
    updatedAt: new Date(),
    userId: user.id,
    warehouseAddress: 'Mexico City, CDMX, Mexico',
  },
  {
    additionalData: {
      chatUrl: null,
      hasTransportation: false,
      originalStatus: 'waiting_for_reservation',
      partnerOrderId: faker.datatype.number({ min: 6, max: 6 }),
      quoteUrl: faker.internet.url(),
      reservationPrice: 199,
      volume: 3,
    },
    clientAddress: 'Av. 22 de Febrero 82, Centro de Azcapotzalco, Azcapotzalco, 02000 Ciudad de México, CDMX, México',
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    finishAt: new Date((new Date()).getTime() + aboutToExpireThreshold + 86400000),
    id: 'leeiCuIvD3UkoeoI4uSK',
    paymentIntentId: null,
    startAt: new Date(),
    status: 'pending-payment',
    subtotal: 199,
    tax: 27.45,
    totalAmount: 199,
    updatedAt: new Date(),
    userId: user.id,
    warehouseAddress: 'Mexico City, CDMX, Mexico',
  },
  {
    additionalData: {
      chatUrl: faker.internet.url(),
      hasTransportation: true,
      originalStatus: 'paid',
      partnerOrderId: faker.datatype.number({ min: 6, max: 6 }),
      quoteUrl: faker.internet.url(),
      reservationPrice: 199,
      volume: 3,
    },
    clientAddress: 'Av. 22 de Febrero 82, Centro de Azcapotzalco, Azcapotzalco, 02000 Ciudad de México, CDMX, México',
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    finishAt: new Date(),
    id: 'QEudcyLPVxyxFMCcAWG3',
    paymentIntentId: 'gUQDJ77JIX0GVy5v6TNc',
    startAt: new Date((new Date()).getTime() - 432000000),
    status: 'done',
    subtotal: 199,
    tax: 27.45,
    totalAmount: 199,
    updatedAt: new Date(),
    userId: user.id,
    warehouseAddress: 'Mexico City, CDMX, Mexico',
  }
];