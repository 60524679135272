<section class="product-detail-container">
  <div class="breadcrumb-container">
    <div class="icon-container">
      <i class="icon icon-chevron-left" (click)="goBack()"></i>
    </div>
    <p-breadcrumb [model]="breadcrumbs" [home]="home" />
  </div>
  <app-retryable-section [error]="errorLoadingProduct" [loading]="loadingProduct" (retry)="getProduct()">
    @if (product) {
      <div class="product-container">
        <div class="product-images-reel">
          @for (image of product.images; track $index) {
            <img class="image" src="{{ image }}" (click)="toggleShowGallery($index)" alt="Imagen del producto" />
          }
        </div>
        <div class="detail-container">
          <div class="information-container">
            <img class="image" src="{{ product.images[0] }}" (click)="toggleShowGallery()" alt="Imagen del producto" />
            <div class="description-container">
              <span class="description" [ngClass]="{ 'collapsed': isCollapsed, 'expanded': !isCollapsed }">{{ product.description }}</span>
              <button class="read-more" (click)="toggleDescription()">{{ (isCollapsed ? 'See more' : 'See less') | transloco }}</button>
            </div>
            <span class="id">ID del producto: {{ product.id }}</span>
          </div>
        </div>
        <div class="data-container">
          <div class="carousel-container">
            <p-carousel class="product-images-carousel"
                        [contentClass]="'carousel display-navigation'"
                        [value]="product.images"
                        [numVisible]="1"
                        [numScroll]="1"
            >
              <ng-template let-item pTemplate="item">
                <img class="image" [src]="item" alt="{{ item.title }}" loading="lazy" />
              </ng-template>
            </p-carousel>
          </div>
          <a class="brand" routerLink="/ecommerce/search" [queryParams]="brandQueryParams">{{ product.brand }}</a>
          <span class="name">{{ product.name }}</span>
          @if (product.discountPercentage >= offerThreshold) {
            <span class="discount-percent">-{{ product.discountPercentage | translocoPercent }}</span>
          }
          <div class="price-container">
            <span class="current-price">{{ product.price | currencyFormatter: product.currency }} <span class="currency">{{ product.currency }}</span></span>
            @if (product.discountPercentage >= offerThreshold) {
              <span class="original-price">{{ product.originalPrice | currencyFormatter: product.currency }}<span class="currency">{{ product.currency }}</span></span>
            }
          </div>
          <!-- TODO F&F: translate this -->
          <span class="installments-text">Paga hasta en 36 mensualidades de {{ product.pricing['credit-36'].monthlyPayment | currencyFormatter: product.currency }}</span>
          <span class="see-more-options" (click)="overlayPanel.toggle($event)">Ver más opciones</span>
          <div class="buttons-container">
            @if (product.status === 'active') {
              <app-button class="add-shopping-cart" label="Agregar al carrito" styleClass="primary dark" [loading]="addingProductToShoppingCart" (action)="addToShoppingCart()" />
              <button class="favorites" (click)="toggleFavorites()">
                @if (isFavorite && !togglingFavorite) {
                  <i class="icon-heart-fill"></i>
                } @else if (!isFavorite && !togglingFavorite) {
                  <i class="icon-heart"></i>
                } @else {
                  <i class="pi pi-spin pi-spinner"></i>
                }
              </button>
            } @else {
              <app-button class="add-shopping-cart inactive" label="Fuera de stock" styleClass="primary expand" [disabled]="true" />
            }
          </div>
          <div class="description-container">
            <span class="description" [ngClass]="{ 'collapsed': isCollapsed, 'expanded': !isCollapsed }">{{ product.description }}</span>
            <button class="read-more" (click)="toggleDescription()">{{ (isCollapsed ? 'See more' : 'See less') | transloco }}</button>
          </div>
          <span class="id">{{ 'Product ID' }}: {{ product.id }}</span>
          @if (product.status === 'active') {
            <div class="delivery-container zip-code">
              <i class="icon icon-shipping"></i>
              <div class="information">
                <span class="title">Entrega estimada</span>
                @if (shippingDates && shippingDates.to && shippingDates.from) {
                  <p class="detail">{{ 'Delivery for zip code X' | transloco: { postalCode: form.controls.postalCode.value! } }} {{ 'is between XX and YY' | transloco: {
                      from: shippingDates.from | date: 'dd MMM',
                      to: shippingDates.to | date: 'dd MMM'
                  } }}</p>
                  <a class="link" (click)="onEnterAnotherZipCode()">Ingresar otro código</a>
                } @else {
                <form [formGroup]="form" class="form-container">
                  <div class="input-container">
                    <label class="detail" for="postal-code">Ingresa código postal para calcular</label>
                    <div class="zip-container">
                      <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" placeholder="Ingresa código postal..." maxlength="5" [class.active]="isActive(this.form.controls.postalCode)" />
                      <app-button class="button" styleClass="tertiary dark expand" label="Calcular" (action)="setShippingDates()" [loading]="loadingShippingDates" />
                    </div>
                  </div>
                </form>
                }
              </div>
            </div>
          }
          <div class="dimensions-container">
            <i class="icon icon-dimensions"></i>
            <div class="information">
              <span class="title">Medidas del producto</span>
              <span class="detail">{{ 'Height: X cm, Width: Y cm, Depth: Z cm' | transloco: { height: product.dimensions.height, depth: product.dimensions.depth, width: product.dimensions.width } }}</span>
            </div>
          </div>
        </div>
      </div>
      <p-galleria class="gallery"
        [value]="product.images"
        [visible]="showGallery"
        [containerStyle]="{ 'max-width': '50%' }"
        [numVisible]="9"
        [circular]="true"
        [fullScreen]="true"
        [showItemNavigators]="true"
        [showThumbnails]="false"
        [activeIndex]="currentPhotoIndex"
        (visibleChange)="toggleShowGallery()">
        <ng-template pTemplate="item" let-item>
          <img class="gallery-item" [src]="item" alt="Imagen del producto" />
        </ng-template>
      </p-galleria>
    }
  </app-retryable-section>
</section>
<section class="products-of-interest-section">
  @if (product) {
    <app-products-carousel
      title="Productos relacionados"
      [filters]="[
        { value: product.categories, operator: 'in', field: 'categories' },
        { value: product.id, operator: '!=', field: 'id' },
      ]"
      ngSkipHydration
    />
  }
</section>
<section class="products-of-interest-section">
  @if (product) {
    <app-products-carousel
      title="Más de {{ product.brand }}"
      [filters]="[
        { value: product.brand, operator: '=', field: 'brand' },
        { value: product.id, operator: '!=', field: 'id' },
      ]"
      ngSkipHydration
    />
  }
</section>
<p-overlayPanel styleClass="installment-options-overlay" #overlayPanel>
  <ng-template pTemplate="content">
    <span class="title-content">Exclusivo Tarjetas Santander</span>
    <div class="separator"></div>
    <div class="titles">
      <span class="title">Plazo</span>
      <span class="title">Descuento</span>
      <span class="title">Pago mensual</span>
    </div>
    <div class="items">
      @for (entry of installmentOptions; track $index) {
        <div class="item">
          <span class="information">{{ entry.term }}</span>
          <span class="information">{{ entry.discount | translocoPercent }}</span>
          <div class="amounts-container">
            @if (product) {
              <span class="amount">{{ entry.monthlyPayment | currencyFormatter: product.currency }} {{ product.currency }}</span>
              <span class="amount">Total: {{ entry.total | currencyFormatter: product.currency }} {{ product.currency }}</span>
            }
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-overlayPanel>