import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MortgageSimulationPaymentTerm } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { constants } from '../../../constants';
import { LocalMortgageSimulation, MortgageSimulatorStep } from '../../../models/mortgage-simulation.model';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { normalizeFloat } from '../../../utils/numbers.utils';
import { valueComparison } from '../../../validators/value-comparison.validator';

@Component({
  selector: 'app-simulation-data',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputNumberModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './simulation-data.page.html',
  styleUrl: './simulation-data.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SimulationDataPage implements OnInit, OnDestroy {
  currency = constants.defaultCurrency;
  form: FormGroup<{
    amountNeeded: FormControl<number | null>;
    downMortgage: FormControl<number | null>;
    paymentTerm: FormControl<MortgageSimulationPaymentTerm | null>;
    propertyValue: FormControl<number | null>;
  }>;
  mortgageSimulation: LocalMortgageSimulation;
  paymentTerms: MortgageSimulationPaymentTerm[] = [20, 15, 10];
  private viewDestroyed = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private platformService: PlatformService,
    private router: Router,
  ) {
    this.form = new FormGroup({
      amountNeeded: new FormControl<number | null>(null),
      downMortgage: new FormControl<number | null>(null, [Validators.required, Validators.min(0.01)]),
      paymentTerm: new FormControl<MortgageSimulationPaymentTerm | null>(null, [Validators.required]),
      propertyValue: new FormControl<number | null>(null),
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  continue(): void {
    if (this.form.invalid) {
      return;
    }
    const { type, stepsCompleted } = this.mortgageSimulation;
    const { amountNeeded, downMortgage, paymentTerm, propertyValue } = this.form.getRawValue();
    this.dataStorageService.set<LocalMortgageSimulation>(DataKey.MortgageSimulation, {
      ...this.mortgageSimulation,
      amountNeeded: type === 'amount' ? amountNeeded! : 0,
      downMortgage: downMortgage!,
      paymentTerm: paymentTerm,
      propertyValue: type === 'housing-cost' ? propertyValue! : normalizeFloat(amountNeeded! + downMortgage!),
      stepsCompleted: Array.from(new Set([...stepsCompleted, MortgageSimulatorStep.SimulationData])),
    });
    this.analyticsService.logMortgageSimulatorEvent({ simulation_type: type!, source: 'app', step: 'step_2' });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/mortgage-simulator/contact-data']);
    // this.router.navigate(['/mortgage-simulator/remodeling-amount']); TODO: Uncomment when remodeling amount is available
  }

  private downMortgageComparedToAmountNeeded(operator: 'gte' | 'lte', percentage: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = 0;
      const amountNeededValue = this.form.controls.amountNeeded.value;
      const downMortgageValue = control.value;

      if (!downMortgageValue || !amountNeededValue) {
        return null;
      }

      value = normalizeFloat((amountNeededValue * 0.1) / ((100 - percentage) / 100));

      if (operator === 'gte') {
        return downMortgageValue >= value ? null : { valueComparison: { operator, value } };
      }

      return downMortgageValue <= value ? null : { valueComparison: { operator, value } };
    };
  }

  private getCurrentSimulation(): Promise<LocalMortgageSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalMortgageSimulation>(DataKey.MortgageSimulation));
  }

  private async initialize(): Promise<void> {
    const currentMortgageSimulation = await this.getCurrentSimulation();
    const shouldContinue = await this.stepPrerequisitesMet(currentMortgageSimulation);
    if (!shouldContinue) {
      return;
    }
    this.mortgageSimulation = currentMortgageSimulation!;
    this.form.controls.downMortgage.patchValue(this.mortgageSimulation.downMortgage || null);
    this.form.controls.paymentTerm.patchValue(this.mortgageSimulation.paymentTerm);
    this.updateDynamicControls();
  }

  private async stepPrerequisitesMet(currentMortgageSimulation: LocalMortgageSimulation | null): Promise<boolean> {
    if (!currentMortgageSimulation || !currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationType)) {
      await this.router.navigate(['/mortgage-simulator']);
      return false;
    }
    return true;
  }

  private updateDynamicControls(): void {
    const { amountNeeded, propertyValue, type } = this.mortgageSimulation;
    if (type === 'amount') {
      this.form.controls.amountNeeded.setValue(amountNeeded || null);
      this.form.controls.amountNeeded.setValidators([Validators.required, Validators.min(normalizeFloat(constants.mortgageSimulator.propertyValue.minimum * constants.mortgageSimulator.loanPercentage.minimum)), Validators.max(normalizeFloat(constants.mortgageSimulator.propertyValue.maximum * constants.mortgageSimulator.loanPercentage.maximum))]);
      this.form.controls.amountNeeded.updateValueAndValidity();
      this.form.controls.amountNeeded.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({ next: () => this.form.controls.downMortgage.updateValueAndValidity() });
      this.form.controls.downMortgage.addValidators([this.downMortgageComparedToAmountNeeded('gte', 10), this.downMortgageComparedToAmountNeeded('lte', 90)]);
      this.form.controls.downMortgage.updateValueAndValidity();
    } else if (type === 'housing-cost') {
      this.form.controls.propertyValue.setValue(propertyValue || null);
      this.form.controls.propertyValue.setValidators([Validators.required, Validators.min(constants.mortgageSimulator.propertyValue.minimum), Validators.max(constants.mortgageSimulator.propertyValue.maximum)]);
      this.form.controls.propertyValue.updateValueAndValidity();
      this.form.controls.propertyValue.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({ next: () => this.form.controls.downMortgage.updateValueAndValidity() });
      this.form.controls.downMortgage.addValidators([valueComparison(this.form.controls.propertyValue, 'gte', 10), valueComparison(this.form.controls.propertyValue, 'lte', 90)]);
      this.form.controls.downMortgage.updateValueAndValidity();
    }
  }
}
