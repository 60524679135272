<section class="mortgage-simulator-contact-data-main-container">
  <div class="left-container">
    <h3 class="title">Ahora, ingresa tus datos</h3>
    <form class="form-container" [formGroup]="form">
      <div class="input-container">
        <label for="full-name">Nombre completo</label>
        <input type="text" id="full-name" pInputText [formControl]="form.controls.fullName" [class.active]="isActive(form.controls.fullName)" maxLength="108" aria-describedby="full-name-help" data-private>
        @if ((form.controls.fullName.touched || form.controls.fullName.dirty) && form.controls.fullName.errors) {
          <small id="full-name-help" class="p-error">Ingresa un nombre completo válido.</small>
        }
      </div>
      <div class="input-container">
        <label for="email">Correo electrónico</label>
        <input id="email" type="email" [class.active]="isActive(form.controls.email)" pInputText [formControl]="form.controls.email" aria-describedby="email-help" data-private />
        @if ((form.controls.email.touched || form.controls.email.dirty) && form.controls.email.errors) {
          <small id="email-help" class="p-error">Ingresa un correo válido.</small>
        }
      </div>
      <div class="input-container">
        <label for="phone-number">Número telefónico</label>
        <p-inputMask id="phone-number" [class.active]="isActive(form.controls.phoneNumber)" [formControl]="form.controls.phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" data-private></p-inputMask>
        @if ((form.controls.phoneNumber.touched || form.controls.phoneNumber.dirty) && form.controls.phoneNumber.errors) {
          <small id="phone-number-help" class="p-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
        }
      </div>
    </form>
    <div class="reminder-container">
      <app-reminder styleClass="alert" title="Importante" description="Se enviará la simulación vía WhatsApp, es importante ingresar un número telefónico válido al cual tengas acceso." />
    </div>
  </div>
  <div class="right-container">
    @if (mortgageSimulation) {
      @if (mortgageSimulation.type === 'amount') {
        <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="Ilustración de simulación de hipoteca por monto que necesitas" />
      } @else if (mortgageSimulation.type === 'housing-cost') {
        <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="Ilustración de simulación de hipoteca por costo de inmueble" />
      }
    }
    <div class="action-button-container animate__fadeIn">
      <app-button id="btn-yQjrPhIW" label="Enviar simulación" styleClass="primary expand" [disabled]="form.invalid" (action)="openConfirmPhoneNumberSidebar()" />
    </div>
  </div>
</section>
@if (form.controls.phoneNumber.value) {
  <app-send-simulation-sidebar #sendSimulationSidebar [phoneNumber]="form.controls.phoneNumber.value" [sendingSimulation]="sendingSimulation" [simulationSent]="simulationSent" (sendSimulation)="sendSimulation()" (simulateAgain)="simulateAgain()"></app-send-simulation-sidebar>
}