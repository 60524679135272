export function smoothHorizontalScroll(element: HTMLElement, distance: number, duration = 300): void {
  const start = element.scrollLeft;
  const startTime = performance.now();
  const animateScroll = (currentTime: number) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    element.scrollLeft = start + distance * progress;
    if (timeElapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  };
  requestAnimationFrame(animateScroll);
}