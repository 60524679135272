import { CommonModule } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PaymentIntent } from '@homein-hogar-server';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressBarModule } from 'primeng/progressbar';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { PurchasesSidebarComponent } from '../../../components/purchases-sidebar/purchases-sidebar.component';
import { constants } from '../../../constants';
import { rfcNaturalPersonPattern } from '../../../constants/validation-patterns.constants';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../../services/forms/forms.service';
import { PaymentIntentsService } from '../../../services/payment-intents/payment-intents.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ToastService } from '../../../services/toast/toast.service';
import { subtract } from '../../../utils/date.utils';
import { isActive } from '../../../utils/forms.utils';
import { dateComparison } from '../../../validators/date-comparison.validor';

@Component({
  selector: 'app-consumer-credit-discount',
  standalone: true,
  imports: [
    ButtonComponent,
    CalendarModule,
    CheckboxModule,
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    ProgressBarModule,
    PurchasesSidebarComponent,
    RecaptchaV3Module,
    ReactiveFormsModule,
  ],
  templateUrl: './consumer-credit-discount.page.html',
  styleUrl: './consumer-credit-discount.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ConsumerCreditDiscountPage {
  @ViewChild('purchasesSidebar') purchasesSidebar: PurchasesSidebarComponent;
  acceptedTermsAndConditions = false;
  benefitKeyPoints = [
    'Entiendo que la rebaja en tasa no es retroactiva y el beneficio aprobado se aplicará desde el mes siguiente a la solicitud.',
    'Entiendo que para acceder al beneficio se requieren compras en Mundo Hogar por un monto equivalente o superior al 70% del monto dispuesto del crédito.',
    'Entiendo que la información ingresada en la solicitud será validada por el banco.',
    'Entiendo que sólo aplican compras con tarjeta de débito posteriores a la fecha de contratación del crédito.',
  ];
  calculatingPurchases = false;
  completedPercentage = 0;
  currency = constants.defaultCurrency;
  displayBenefitKeyPoints = true;
  displayConsumerCreditForm = false;
  displayMeetsRequirements = false;
  displayRequestSuccess = false;
  form: FormGroup<{
    creditAmount: FormControl<number | null>;
    requestedCreditDate: FormControl<Date | null>;
    rfc: FormControl<string | null>;
  }>;
  maxRequestedCreditDate = new Date();
  meetsRequirements = false;
  paymentIntents: PaymentIntent[] = [];
  purchasesAmount = 0;
  sendingRequest = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private paymentIntentsService: PaymentIntentsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private seoService: SeoService,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      creditAmount: new FormControl<number | null>(null, [Validators.required, Validators.min(0.01), Validators.max(2000000000000)]),
      requestedCreditDate: new FormControl<Date | null>(null, [Validators.required, dateComparison(subtract(new Date(), constants.consumerCreditDiscountBenefit.requestedCreditDateSubtraction, 'days'), 'gte')]),
      rfc: new FormControl<string | null>(null, [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(rfcNaturalPersonPattern)]),
    });
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  async calculatePurchases(): Promise<void> {
    if (this.form.invalid || this.calculatingPurchases) {
      return;
    }
    this.calculatingPurchases = true;
    try {
      // TODO: Implement method that get paid payments intents filtered by date range
      const paymentIntents = await firstValueFrom(this.paymentIntentsService.getAllPaid());
      this.paymentIntents = paymentIntents.filter((paymentIntent) => paymentIntent.apportions.external?.some((apportion) => apportion.option === 'debit') && new Date(paymentIntent.createdAt).getTime() >= this.form.controls.requestedCreditDate.value!.getTime());
      this.purchasesAmount = this.paymentIntents.reduce((amount, paymentIntent) => amount + paymentIntent.amount, 0);
      this.completedPercentage = this.purchasesAmount / this.form.controls.creditAmount.value!;
      this.meetsRequirements = this.completedPercentage >= constants.consumerCreditDiscountBenefit.threshold;
      this.displayConsumerCreditForm = false;
      this.displayMeetsRequirements = true;
    } catch (error) {
      this.errorReportingService.log('ConsumerCreditDiscountPage.calculatePurchases()', 'get-all-paid-payment-intents', error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
    } finally {
      this.calculatingPurchases = false;
    }
  }

  goBack(): void {
    this.displayMeetsRequirements = false;
    this.displayConsumerCreditForm = true;
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  seeAllPurchases(): void {
    this.purchasesSidebar.open();
  }

  async sendRequest(): Promise<void> {
    if (this.sendingRequest) {
      return;
    }
    let step = '';
    this.sendingRequest = true;
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitConsumerCreditDiscount'));
      step = 'submit-consumer-credit-discount';
      await this.formsService.submitConsumerCreditDiscount({
        creditAmount: this.form.controls.creditAmount.value!,
        requestedCreditDate: this.form.controls.requestedCreditDate.value!,
        rfc: this.form.controls.rfc.value!,
        recaptchaToken,
      });
      this.purchasesSidebar.close();
      this.displayMeetsRequirements = false;
      this.displayRequestSuccess = true;
    } catch (error) {
      this.errorReportingService.log('ConsumerCreditDiscountPage.sendRequest()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
    } finally {
      this.sendingRequest = false;
    }
  }

  start(): void {
    this.displayBenefitKeyPoints = false;
    this.displayConsumerCreditForm = true;
  }
}
