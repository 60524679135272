<section class="main-benefits-section">
  <div class="main-container">
    <div class="left-container">
      <div class="title-container">
        <h2 class="title">Personaliza tus espacios en Mundo Hogar y accede a una rebaja en la tasa de tu crédito</h2>
      </div>
      <p class="description">Solicita un Crédito Personal Santander, y obtén una rebaja en tu tasa de interés, para financiar cualquier proyecto o compra en Mundo Hogar</p>
      <div class="button-container">
        <app-button label="Solicitar beneficio" styleClass="primary expand" routerLink="/benefits/consumer-credit-discount" />
      </div>
    </div>
  </div>
</section>
<section class="how-benefits-works-section">
  <div class="container">
    <h2 class="main-title">¿Cómo funciona?</h2>
    <hr class="underline">
    <div class="items">
      <div class="item">
        <div class="number-container">
          <span class="number">1</span>
        </div>
        <div class="title-container">
          <h4 class="title">Solicita tu crédito</h4>
        </div>
        <p class="subtitle">Solicita tu crédito personal en cualquier sucursal Santander.</p>
        <div class="button-container">
          <app-button styleClass="tertiary" label="Saber más" href="https://www.santander.com.mx/personas/creditos-personales.html" />
        </div>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">2</span>
        </div>
        <div class="title-container">
          <h4 class="title">Compra en Mundo Hogar</h4>
        </div>
        <p class="subtitle">Realiza compras por al menos un 70% del monto del crédito en los productos o servicios de Mundo Hogar.</p>
        <div class="button-container">
          <app-button styleClass="tertiary" label="Ir a compras" routerLink="/ecommerce" />
        </div>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">3</span>
        </div>
        <div class="title-container">
          <h4 class="title">Solicita tu beneficio</h4>
        </div>
        <p class="subtitle">Solicita y espera la aprobación de tu rebaja en la tasa de tu Crédito Personal.</p>
        <div class="button-container">
          <app-button styleClass="tertiary" label="Solicitar rebaja" routerLink="/benefits/consumer-credit-discount" />
        </div>
      </div>
    </div>
    <p class="disclaimer">La rebaja en tasa se hará efectiva a partir del momento de la solicitud (no es retroactiva).</p>
  </div>
</section>
<section class="what-can-you-buy-section">
  <div class="container">
    <h2 class="title">¿Qué puedes comprar con tu Crédito Personal en Mundo Hogar?</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <div class="benefit-content">
          <img class="image desktop" src="/assets/images/benefits/benefits-desktop-01.png" alt="Imagen de beneficios" />
          <img class="image mobile" src="/assets/images/benefits/benefits-mobile-01.png" alt="Imagen de beneficios" />
          <h4 class="label">Compras para el hogar</h4>
          <span class="description">Muebles, electrodomésticos, decoración y más.</span>
          <app-button class="button" styleClass="tertiary" label="Ir a comprar" routerLink="/ecommerce" />
        </div>
      </div>
      <div class="benefit">
        <div class="benefit-content">
          <img class="image desktop" src="/assets/images/benefits/benefits-desktop-02.png" alt="Imagen de beneficios">
          <img class="image mobile" src="/assets/images/benefits/benefits-mobile-02.png" alt="Imagen de beneficios">
          <h4 class="label">Remodelaciones</h4>
          <span class="description">Remodelación o equipamiento para tu inmueble.</span>
          <app-button class="button" styleClass="tertiary" label="Simular remodelación" routerLink="/project-simulator/step-1" />
        </div>
      </div>
      <div class="benefit">
        <div class="benefit-content">
          <img class="image desktop" src="/assets/images/benefits/benefits-desktop-03.png" alt="Imagen de beneficios">
          <img class="image mobile" src="/assets/images/benefits/benefits-mobile-03.png" alt="Imagen de beneficios">
          <h4 class="label">Mudanzas</h4>
          <span class="description">Las mejores mudanzas en CDMX sin estrés.</span>
          <app-button class="button" styleClass="tertiary" label="Cotizar mudanza" routerLink="/moving" />
        </div>
      </div>
      <div class="benefit">
        <div class="benefit-content">
          <img class="image desktop" src="/assets/images/benefits/benefits-desktop-04.png" alt="Imagen de beneficios">
          <img class="image mobile" src="/assets/images/benefits/benefits-mobile-04.png" alt="Imagen de beneficios">
          <h4 class="label">Planes de asistencias</h4>
          <span class="description">Contratación de planes de asistencias para tu hogar o mascota.</span>
          <app-button class="button" styleClass="tertiary" label="Revisar planes" routerLink="/home-assistance" />
        </div>
      </div>
      <div class="benefit">
        <div class="benefit-content">
          <img class="image desktop" src="/assets/images/benefits/benefits-desktop-05.png" alt="Imagen de beneficios">
          <img class="image mobile" src="/assets/images/benefits/benefits-mobile-05.png" alt="Imagen de beneficios">
          <h4 class="label">Renta de bodegas</h4>
          <span class="description">Renta tu próxima bodega ubicadas estratégicamente.</span>
          <app-button class="button" styleClass="tertiary" label="Rentar bodega" routerLink="/warehouse" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="benefits-personal-credit-section">
  <div class="container">
    <div class="right-container">
      <img class="image" src="/assets/illustrations/benefits-group.svg" alt="Imagen de personas siendo atentidas por un ejecutivo bancario" />
    </div>
    <div class="left-container">
      <div class="title-container">
        <h2 class="title">Beneficios del Crédito Personal Santander</h2>
      </div>
      <div class="items-container">
        <ol class="items">
          <div class="item-wrapper"><i class="icon icon-checkmark"></i><li class="item">Tasas de interés más convenientes del mercado.</li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark"></i><li class="item">Selecciona el plazo más adecuado a tus necesidades.</li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark"></i><li class="item">Plazos fijos en cada disposición.</li></div>
        </ol>
        <div class="button-container">
          <app-button styleClass="secondary expand" label="Saber más" href="https://www.santander.com.mx/personas/creditos-personales.html" target="_blank" rel="noopener" />
        </div>
      </div>
    </div>
  </div>
</section>
<article class="faq-article">
  <h2 class="title">Preguntas frecuentes</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="¿Cómo solicitar rebaja en tasa de interés?" iconPos="end">
        <div class="text-container">
          <p class="text">Ingresa a la sección de Solicitar beneficio, ingresa los datos solicitados y un ejecutivo revisará la información para confirmar estos datos y aplicar la reducción en la rebaja de tasa</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Cuánto tiempo tengo para solicitar rebaja en tasa?" iconPos="end">
        <div class="text-container">
          <p class="text">Hasta 90 días posterior a la contratación del crédito. La rebaja en tasa se hará efectiva a contar del momento de la solicitud (no es retroactiva).</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Cómo solicito factura en los productos y servicios de Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Una vez realizada tu compra, realiza los siguientes pasos:</p>
        </div>
        <div class="text-container">
          <ul>
            <li>En mis servicios, selecciona la compra que deseas facturar.</li>
            <li>Encuentra el pedido correspondiente y selecciona Detalles del pedido.</li>
            <li>Selecciona Solicitar factura electrónica (CFDI).</li>
          </ul>
          <p class="text">Una vez que la factura esté disponible, te enviaremos el PDF y XML correspondientes a la factura.</p>
          <ul>
            <li>Para generar facturas electrónicas (CFDI) ingresa tus datos fiscales y genera la factura. Recuerda que solo puedes facturar tus compras de la sección de Compras y Asistencias para el hogar.</li>
          </ul>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué tipo de compras aplican para el beneficio?" iconPos="end">
        <div class="text-container">
          <p class="text">Todas las compras que realices en Mundo Hogar aplican para el beneficio. Recuerda que tus compras deben alcanzar al menos el 70% del crédito otorgado para obtener el beneficio de rebaja en tasa.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">Visita nuestra sección <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank">Ayuda</a> para encontrar más</h5>
</article>
