import { Injectable } from '@angular/core';
import { MortgageSimulationType, ProjectSimulationExecutionTimeFrame, ProjectSimulationType } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { AnalyticsServiceApi } from './analytics.service.api';
import { AnalyticsServiceMock } from './analytics.service.mock';

interface BaseCheckoutEvent {
  currency: string;
  items: CheckoutItem[];
  value: number;
}

export interface CheckoutItem {
  discount: number;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
}

export interface GenerateLeadEvent {
  lead_source: string;
}

export interface HomeAssistanceEvent {
  duration?: string;
  plan?: string;
  step: 'begin_checkout' | 'confirm_payment' | 'purchase' | 'select_duration' | 'start' | 'view';
}

export interface MovingEvent {
  service?: string;
  step: 'begin_checkout' | 'confirm_payment' | 'purchase' | 'start' | 'start_quote';
}

export interface RemodelingEvent {
  service?: string;
  step: 'begin_checkout' | 'confirm_payment' | 'purchase' | 'request_quote' | 'start' | 'start_quote';
}

export interface WarehouseEvent {
  service?: string;
  step: 'begin_checkout' | 'confirm_payment' | 'purchase' | 'start' | 'start_quote';
}

export interface SignInEvent {
  method: string;
  redirect_to?: string;
}

export interface SignUpEvent {
  method: string;
  redirect_to?: string;
}

export interface SearchEvent {
  search_term: string;
}

export interface UserCreationEvent {
  method?: string;
  step: string;
}

export type AddShippingAddressEvent = BaseCheckoutEvent & {
  shipping_tier?: string;
}
export type AddToFavoritesEvent = BaseCheckoutEvent;
export type AddToShoppingCartEvent = BaseCheckoutEvent;
export type BeginCheckoutEvent = BaseCheckoutEvent & {
  coupon?: string;
}
export type PurchaseEvent = BaseCheckoutEvent & {
  coupon?: string;
  shipping: number;
  tax: number;
  transaction_id: string;
}
export type RemoveFromShoppingCartEvent = BaseCheckoutEvent;
export type ViewItemEvent = BaseCheckoutEvent;
export type ViewShoppingCartEvent = BaseCheckoutEvent;

export type MortgageSimulatorEvent = {
  source: 'app';
  step: 'start';
} | {
  simulation_type: MortgageSimulationType;
  source: 'app';
  step: 'generate' | 'simulate_again' | 'step_1' | 'step_2' | 'step_3' | 'step_4';
};

export type ProjectSimulatorEvent = {
  source: 'app';
  step: 'start';
} | {
  source: 'app';
  step: 'step_1';
  time_frame: ProjectSimulationExecutionTimeFrame;
} | {
  remodeling_type: ProjectSimulationType;
  source: 'app';
  step: 'generate' | 'simulate_again' | 'step_2' | 'step_3' | 'step_4' | 'step_5' | 'step_6';
  time_frame: ProjectSimulationExecutionTimeFrame;
};

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AnalyticsServiceMock : AnalyticsServiceApi,
})
export abstract class AnalyticsService {
  abstract logAddShippingAddressEvent(data: AddShippingAddressEvent): void;
  abstract logAddToFavoritesEvent(data: AddToFavoritesEvent): void;
  abstract logAddToShoppingCartEvent(data: AddToShoppingCartEvent): void;
  abstract logBeginCheckoutEvent(data: BeginCheckoutEvent): void;
  abstract logGenerateLeadEvent(data: GenerateLeadEvent): void;
  abstract logHomeAssistanceEvent(data: HomeAssistanceEvent): void;
  abstract logMortgageSimulatorEvent(data: MortgageSimulatorEvent): void;
  abstract logMovingEvent(data: MovingEvent): void;
  abstract logProjectSimulatorEvent(data: ProjectSimulatorEvent): void;
  abstract logPurchaseEvent(data: PurchaseEvent): void;
  abstract logRemodelingEvent(data: RemodelingEvent): void;
  abstract logRemoveFromShoppingCartEvent(data: RemoveFromShoppingCartEvent): void;
  abstract logSearchEvent(data: SearchEvent): void;
  abstract logSignInEvent(data: SignInEvent): void;
  abstract logSignUpEvent(data: SignUpEvent): void;
  abstract logUserCreationEvent(data: UserCreationEvent): void;
  abstract logViewItemEvent(data: ViewItemEvent): void;
  abstract logViewShoppingCartEvent(data: ViewShoppingCartEvent): void;
  abstract logWarehouseEvent(data: WarehouseEvent): void;
}
