<section class="main">
  <div class="main-container">
    <div class="left-side"></div>
    <div class="right-side">
      <div class="title-container">
        <h2 class="title">Complementa tu plan Hogar + Mascotas</h2>
        <p class="subtitle">Evita dolores de cabeza y disfruta tu hogar y mascotas sin contratiempos o inconvenientes.</p>
      </div>
      <div class="button-container">
        <app-button label="Quiero contratar" styleClass="primary" (action)="openSelectPlan()" />
      </div>
    </div>
  </div>
</section>
<section class="terms-banner">
  <app-terms-banner page="home-assistance" />
</section>
<section class="assistance-request-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">Cuida tu hogar y a tu mejor amigo en un solo plan</h2>
      <hr class="underline">
    </div>
    <div class="request-container">
      <ul class="requests">
        <li class="request">
            <i class="icon-checkmark-in-a-circle"></i>Protege cada espacio de tu hogar y a los que más quieres
        </li>
        <li class="request">
            <i class="icon-checkmark-in-a-circle"></i>Amplia red de prestadores y profesionales, capacitados para brindarte el mejor servicio.
        </li>
        <li class="request">
            <i class="icon-checkmark-in-a-circle"></i>Lo servicios más demandados de ambos planes, en un único plan para ti
        </li>
      </ul>
    </div>
  </div>
</section>
<section class="benefits-plan-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">Este plan incluye beneficios como:</h2>
      <hr class="underline">
    </div>
    <div class="benefits-container">
      <div class="benefit">
        <img class="image" src="/assets/images/home-plumbing.svg" alt="imagen de plomería" />
        <div class="content">
          <h4 class="label">Plomería</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/hogar-online-vet.svg" alt="Imagen de veterinario online" />
        <div class="content">
          <h4 class="label">Veterinario online</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/hogar-glassworks.svg" alt="Imagen de vidriería" />
        <div class="content">
          <h4 class="label">Vidriería</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/many-more.svg" alt="Imagen de muchos más" />
        <div class="content">
          <h4 class="label">Muchos más</h4>
        </div>
      </div>
    </div>
    <div class="button-container">
      <app-button label="Contratar servicio" styleClass="secondary" (action)="openSelectPlan()" />
    </div>
    <div class="details">
      <span class="detail">Podrás ver el detalle de este plan <a class="here" (click)="openPlanDetails()">AQUÍ</a>.</span>
    </div>
  </div>
</section>
<section class="separate-plans-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">También puede consultar los planes por separado</h2>
      <hr class="underline">
    </div>
    <div class="plans-container">
      <div class="left-container">
        <img class="image" src="assets/images/plan-hogar-single.svg" alt="Imagen de Plan Hogar" />
        <p class="subtitle">Por esta vez solo quiero Plan Hogar</p>
        <app-button label="Ir a Plan Hogar" styleClass="secondary" routerLink="/home-assistance/plan-hogar" />
      </div>
      <div class="right-container">
        <img class="image" src="assets/images/plan-mascotas-single.svg" alt="Imagen de Plan Mascotas" />
        <p class="subtitle">Por esta vez solo quiero Plan Mascotas</p>
        <app-button label="Ir a Plan Mascotas" styleClass="secondary" routerLink="/home-assistance/plan-mascotas" />
      </div>
    </div>
  </div>
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">¿Por qué Mundo Hogar?</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">Todo en un mismo lugar</h4>
          <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">Beneficios exclusivos</h4>
          <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">Los mejores proveedores</h4>
          <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">Simple y transparente</h4>
          <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí.</a><p>
    </div>
  </div>
</article>
<article class="faq-article">
  <h2 class="title">Preguntas frecuentes</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="¿Qué son las asistencias de Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Son soluciones eficientes ante cualquier urgencia, imprevisto o inconveniente que pueda surgir en tu hogar o a tus mascotas.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué es un plan de asistencias Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Es un programa de asistencias que ofrece cobertura frente a los inconvenientes o imprevistos que debas resolver durante el periodo de vigencia de tu plan. Consulta los eventos que incluye cada uno de los planes disponibles.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué es un evento y límite de eventos?" iconPos="end">
        <div class="text-container">
          <p class="text">Un evento es cada vez que requieres hacer uso de alguno de los servicios incluidos en tu plan de asistencias. Los límites de eventos es la cantidad de veces que puedes usar un servicio en el mismo periodo.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué servicios incluye mi plan de asistencia?" iconPos="end">
        <div class="text-container">
          <p class="text">Mundo Hogar tiene los planes adecuados para proteger lo que más te importa: <a class="link" routerLink="/home-assistance/plan-hogar">Plan Hogar, </a><a class="link" routerLink="/home-assistance/plan-mascotas">Plan Mascotas</a>, <a class="link" routerLink="/home-assistance/plan-hogar-mascotas">Plan Hogar + Mascotas</a>.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">Visita nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
</article>
<app-home-assistance-plan-details-sidebar #assistancePlanDetailsSidebar planId="plan-hogar-mascotas" title="Plan Hogar + Mascotas" />
<app-select-assistance-plan-sidebar #selectAssistancePlanSidebar planId="plan-hogar-mascotas" title="Plan Hogar + Mascotas" />
