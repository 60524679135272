<div class="slider-container">
  <div class="chips-container" #chipsContainer>
    @for (item of chipItems; track $index) {
      <span class="chip" [id]="item.label" [class.selected]="item.label === selectedLabel" (click)="selectItem(item)">{{ item.label }}</span>
    }
  </div>
  @if (showLeftNavigation) {
    <div class="navigation left" (click)="scrollLeft()">
      <i class="icon icon-arrow-left"></i>
    </div>
  }
  @if (showRightNavigation) {
    <div class="navigation right" (click)="scrollRight()">
      <i class="icon icon-arrow-right"></i>
    </div>
  }
</div>