<div class="onboarding-container">
  <section class="onboarding-section">
    <div class="column left">
      <h1 class="title">¡Ya casi! Completa tus datos</h1>
      <form [formGroup]="form" class="form-container">
        <div class="input-container">
          <label for="name">Nombre*</label>
          <input type="text" id="name" [class.active]="isActive(this.form.controls.name)" pInputText [formControl]="this.form.controls.name" maxLength="36" aria-describedby="name-help" data-private />
          <small id="name-help" [class.hidden]="!this.form.controls.name.touched || !this.form.controls.name.errors" class="p-error input-error">Ingresa un nombre válido.</small>
        </div>
        <div class="input-container">
          <label for="father-last-name">Primer apellido*</label>
          <input type="text" id="father-last-name" [class.active]="isActive(this.form.controls.fatherLastName)" pInputText [formControl]="this.form.controls.fatherLastName" maxLength="36" aria-describedby="father-last-name-help" data-private />
          <small id="father-last-name-help" [class.hidden]="!this.form.controls.fatherLastName.touched || !this.form.controls.fatherLastName.errors" class="p-error input-error">Ingresa un primer apellido válido.</small>
        </div>
        <div class="input-container">
          <label for="mother-last-name">Segundo apellido</label>
          <input type="text" id="mother-last-name" [class.active]="isActive(this.form.controls.motherLastName)" pInputText [formControl]="this.form.controls.motherLastName" maxLength="36" aria-describedby="mother-last-name-help" data-private />
          <small id="mother-last-name-help" [class.hidden]="!this.form.controls.motherLastName.touched || !this.form.controls.motherLastName.errors" class="p-error input-error">Ingresa un segundo apellido válido.</small>
        </div>
        <div class="input-container">
          <label for="phone-number">Número de teléfono móvil*</label>
          <p-inputMask id="phone-number" [class.active]="isActive(this.form.controls.phoneNumber)" [formControl]="this.form.controls.phoneNumber" [unmask]="true" [autoClear]="false" mask="+(99) 99 9999 9999" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" data-private></p-inputMask>
          <small id="phone-number-help" [class.hidden]="!this.form.controls.phoneNumber.touched || !this.form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
        </div>
        <div class="input-container">
          <label for="birth-date">Fecha de nacimiento*</label>
          <p-calendar id="birth-date" [class.active]="isActive(this.form.controls.birthDate)" [formControl]="this.form.controls.birthDate" [maxDate]="maxDateBirthDate" [defaultDate]="maxDateBirthDate" dateFormat="dd/mm/yy" aria-describedby="birth-date-help"></p-calendar>
          <small id="birth-date-help" [class.hidden]="this.form.controls.birthDate.pristine || !this.form.controls.birthDate.touched || !this.form.controls.birthDate.errors" class="p-error input-error">Ingresa una fecha de nacimiento válida.</small>
        </div>
        <p class="required-fields">Campos obligatorios*</p>
        <p class="legal-text">Al hacer clic en el botón "Guardar datos" estás aceptando nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank" rel="noopener">Aviso de privacidad</a> y <a class="link" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank" rel="noopener">Términos y condiciones.</a></p>
        <app-button label="Guardar datos" styleClass="primary expand" [disabled]="!form.valid || saving" [loading]="saving" (action)="save()" />
      </form>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">Mundo Hogar</h2>
        <p class="subtitle">Estás a unos pasos de activar tu cuenta</p>
      </div>
    </div>
  </section>
</div>
