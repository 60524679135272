import { Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { smoothHorizontalScroll } from '../../utils/animations.utils';

interface Item {
  label: string;
}

@Component({
  selector: 'app-chips-slider',
  templateUrl: './chips-slider.component.html',
  styleUrls: ['./chips-slider.component.scss'],
  standalone: true,
})
export class ChipsSliderComponent<T extends Item> implements DoCheck, OnChanges {
  @Output() itemSelected = new EventEmitter<T>();
  @Input({ required: true }) items: T[];
  @Input() selectedLabel: string | null = null;
  @ViewChild('chipsContainer', { static: true }) private chipsContainer: ElementRef;
  protected chipItems: T[];
  protected showLeftNavigation = false;
  protected showRightNavigation = false;

  ngDoCheck(): void {
    this.checkWidths();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedLabel'] && this.selectedLabel !== changes['selectedLabel'].currentValue && !this.items.find(item => item.label === changes['selectedLabel'].currentValue)) {
      this.selectedLabel = null;
    }
    this.orderItems();
  }

  protected scrollLeft(): void {
    smoothHorizontalScroll(this.chipsContainer.nativeElement, -200);
  }

  protected scrollRight(): void {
    smoothHorizontalScroll(this.chipsContainer.nativeElement, 200);
  }

  protected selectItem(item: T): void {
    this.selectedLabel = item.label;
    this.itemSelected.emit(item);
  }

  private checkWidths(): void {
    const chipsContainerWidth = this.chipsContainer.nativeElement.offsetWidth;
    const chips: HTMLElement[] = Array.from(this.chipsContainer.nativeElement.querySelectorAll('.chip'));
    const chipsWidth = chips.reduce((width, chip: HTMLElement, index) => {
      const gap = index === chips.length - 1 ? 0 : 16;
      return width + chip.offsetWidth + gap;
    }, 0);
    const { clientWidth, scrollLeft, scrollWidth } = this.chipsContainer.nativeElement;
    this.showRightNavigation = clientWidth ? chipsWidth > chipsContainerWidth && scrollWidth - clientWidth > scrollLeft : chipsWidth > chipsContainerWidth;
    this.showLeftNavigation = scrollLeft > 0;
  }

  private orderItems(): void {
    if (!this.selectedLabel) {
      this.chipItems = this.items;
      return;
    }
    const selectedItems = this.items.filter(item => item.label === this.selectedLabel);
    const otherItems = this.items.filter(item => item.label !== this.selectedLabel);
    this.chipItems = [...selectedItems, ...otherItems];
  }
}