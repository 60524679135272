<section class="moving-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" routerLink="/private-site/my-movings/{{ orderId }}"></i>
    }
    <h2 class="title">Detalle de compra</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (order) {
      <div class="total-container">
        <div class="purchase-detail">{{ order.createdAt | date: 'dd/MM/yyyy' }} | #{{ order.id }}</div>
        <div class="products-value">
          <span class="label">Reserva</span>
          <span class="value">{{ order.subtotal | currencyFormatter: order.currency }} {{ order.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</div>
        </div>
      </div>
      <div class="app-purchase-payment">
        @if (order.paymentIntentId) {
          <app-purchase-payment-details [paymentIntentIds]="[order.paymentIntentId]" />
        }
      </div>
      <div class="moving-container">
        <h2 class="title">Detalles de la mudanza</h2>
        <div class="address-container">
          <div class="address-wrapper">
            <div class="icon-container">
              <i class="icon-location-start"></i>
            </div>
            <div class="info">
              <span class="address" data-private>{{ startAddress.firstLine }}</span>
              <span class="address" data-private>{{ startAddress.secondLine }}</span>
            </div>
          </div>
          <div class="address-wrapper">
            <div class="icon-container">
              <i class="icon-location-end"></i>
            </div>
            <div class="info">
              <span class="address" data-private>{{ endAddress.firstLine }}</span>
              <span class="address" data-private>{{ endAddress.secondLine }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="moving-items-container">
        <p-accordion class="accordion-moving-items">
            <p-accordionTab class="accordion-items" header="{{ 'Moving items (X items)' | transloco: { quantity: order.items.length } }}" iconPos="end">
            @for (item of order.items; track $index) {
              <div class="item">
                <span class="name">{{ item.name }}</span>
                <span class="quantity">{{ item.quantity }} u.</span>
              </div>
            }
          </p-accordionTab>
        </p-accordion>
      </div>
    }
  </app-retryable-section>
</section>