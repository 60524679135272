<p-sidebar [(visible)]="visible" styleClass="sidebar-container" position="left" blockScroll="false" showCloseIcon="false" (onHide)="close()">
  <ng-template pTemplate="headless">
    <div class="title-container">
      <h1 class="title">Mundo Hogar</h1>
      <p-button class="button" id="btn-link-ediU4_Ad" icon="pi pi-times" [rounded]="true" [text]="true" severity="secondary" (click)="close()" />
    </div>
    <p-scrollPanel [style]="scrollPanelConfig" class="scrollpanel">
      <p-accordion class="menu-sidebar" [multiple]="true" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab-home" [class.selected]="currentPage === 'home'" iconPos="end">
          <ng-template pTemplate="header">
            <a class="accordion-header" [routerLink]="'/private-site'">
              <i class="icon icon-house"></i>
              <span class="category-title">Mis servicios</span>
            </a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'my-profile'" class="accordion-tab" [class.selected]="currentPage === 'my-profile'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-account-user"></i>
              <span class="category-title">Mi cuenta</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/private-site/profile/my-balance" id="btn-link-q_fJP4qb">Saldos y movimientos</a>
            <p-accordionTab class="subcategory-title" header="Mis compras y servicios" iconPos="end" [selected]="true">
                <a class="selectable" routerLink="/private-site/my-home-assistances" id="btn-link-ZqLwM8x1">Mis planes de asistencia</a>
                <a class="selectable" routerLink="/warehouse" id="btn-link-AcFjQ7p8">Mis bodegas</a>
                <a class="selectable" routerLink="/private-site/my-purchases" id="btn-link-YxErF5o9">Mis compras</a>
                <a class="selectable" routerLink="/private-site/my-movings" id="btn-link-CxLzK6v2">Mis mudanzas</a>
                <a class="selectable" routerLink="/private-site/my-remodelings" id="btn-link-PxYfD3g4">Mis remodelaciones</a>
            </p-accordionTab>
            <a class="selectable" routerLink="/private-site/profile/my-personal-data" id="btn-link-FxBvG1q3">Datos personales</a>
          </ng-template>
        </p-accordionTab>
        <p-divider class="divider" />
        <p-accordionTab [selected]="currentPage === 'remodeling'" class="accordion-tab" [class.selected]="currentPage === 'remodeling'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-remodeling"></i>
              <span class="category-title">Remodelación</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/remodeling" id="btn-link-QvNjD7x9">Ir a remodelación</a>
            <a class="selectable" routerLink="/project-simulator" id="btn-link-WbMfH4r5">Simular remodelación</a>
            <a class="selectable" routerLink="/remodeling" [queryParams]="{ action: 'quote' }" id="btn-link-VdKhJ2u1">Cotizar una remodelación</a>
            <a class="selectable" routerLink="/private-site/my-remodelings" id="btn-link-ZnLxK9m3">Mis remodelaciones</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'moving'" class="accordion-tab" [class.selected]="currentPage === 'moving'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-truck"></i>
              <span class="category-title">Mudanzas</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/moving" id="btn-link-TbGjN8o5">Ir a mudanzas</a>
            <a class="selectable" routerLink="/moving" [queryParams]="{ action: 'quote' }" id="btn-link-XpNjQ4v7">Cotizar mi mudanza</a>
            <a class="selectable" routerLink="/private-site/my-movings" id="btn-link-RcFxL2d8">Mis mudanzas</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'ecommerce'" class="accordion-tab" [class.selected]="currentPage === 'ecommerce'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-armchair"></i>
              <span class="category-title">Compras para el hogar</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/ecommerce" id="btn-link-KfHvC9x2">Ir a comprar</a>
            <a class="selectable" routerLink="/ecommerce/my-favorites" id="btn-link-PzLfG7o9">Mis favoritos</a>
            <a class="selectable" routerLink="/ecommerce/shopping-cart" id="btn-link-UgNvR3y1">Mi carrito de compras</a>
            <a class="selectable" routerLink="/private-site/my-purchases" id="btn-link-WxJfK5z4">Mis compras</a>
            <p-accordionTab class="subcategory-title" header="Categorías" iconPos="end">
              @for (category of categories; track $index) {
                <p-accordionTab class="sub-subcategory-title" [header]="category.label" iconPos="end">
                  @for (children of category.children; track $index) {
                    <a class="sub-subcategory-text selectable" routerLink="/ecommerce/search" [queryParams]="children.queryParams">{{ children.label }}</a>
                  }
                </p-accordionTab>
              }
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'warehouse'" class="accordion-tab" [class.selected]="currentPage === 'warehouse'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-warehouses"></i>
              <span class="category-title">Rentar bodega</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/warehouse" id="btn-link-cs3eEkuw">Ir a bodegas</a>
            <a class="selectable" routerLink="/warehouse" id="btn-link-3mcpSezl">Mis bodegas</a>
            <a class="selectable" routerLink="/warehouse" id="btn-link-Bz_GOMtT">Cotizar mi bodega</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'home-assistance'" class="accordion-tab" [class.selected]="currentPage === 'home-assistance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-home-assistance"></i>
              <span class="category-title">Planes de asistencia</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/home-assistance" id="btn-link-dUIc_slp">Ir a asistencias</a>
            <a class="selectable" routerLink="/private-site/my-home-assistances" id="btn-link-Psys3dBp">Mis planes de asistencia</a>
            <p-accordionTab class="subcategory-title" header="Cotizar mi plan de asistencia" iconPos="end">
              <a class="selectable" routerLink="/home-assistance/plan-hogar" id="btn-link-3emmUbJb">Plan Hogar</a>
              <a class="selectable" routerLink="/home-assistance/plan-mascotas" id="btn-link-gl9Qd06c">Plan Mascotas</a>
              <a class="selectable" routerLink="/home-assistance/plan-hogar-mascotas" id="btn-link-PFFG_qWB">Plan Hogar + Mascotas</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'finance-and-insurance'" class="accordion-tab" [class.selected]="currentPage === 'finance-and-insurance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-hand-plant"></i>
              <span class="category-title">Financiamiento y seguros</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://www.santander.com.mx/personas/creditos-hipotecarios.html" target="_blank" rel="noopener" id="btn-link-L9LtQe8Q">Hipoteca Santander</a>
            <a class="selectable" routerLink="/benefits" id="btn-link-eTO-lzef">Crédito Personal Santander</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/tarjetas-de-credito.html" target="_blank" rel="noopener" id="btn-link-Sn29E2O5">Tarjetas Santander</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/seguros.html" target="_blank" rel="noopener" id="btn-link-M5qsLOeZ">Seguros Santander</a>
            <p-accordionTab class="subcategory-title" header="Simuladores" iconPos="end">
              <a class="selectable" routerLink="/mortgage-simulator" id="btn-link-wxjKjuoj">Simular hipoteca</a>
              <a class="selectable" routerLink="/project-simulator" id="btn-link-uTqM7wJQ">Simular remodelación</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'help-center'" class="accordion-tab" [class.selected]="currentPage === 'help-center'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-help-circle"></i>
              <span class="category-title">Centro de ayuda</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/" target="_blank" id="btn-link-G1SsRzu9">Ir al centro de ayuda</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/preguntas-frecuentes" target="_blank" id="btn-link-SWrWyW5i">Preguntas frecuentes</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank" id="btn-link-0bsSyfj3">Aviso de privacidad</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank" id="btn-link-t7gBo3FW">Términos y condiciones</a>
            <a class="selectable" routerLink="/complaints-and-returns" id="btn-link-JXqiUDC0">Reclamos y devoluciones</a>
            <a class="selectable" routerLink="/contact" id="btn-link-OzLdlMXR">Contacto</a>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </p-scrollPanel>
  </ng-template>
</p-sidebar>