import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { LottieComponent } from 'ngx-lottie';
import { firstValueFrom } from 'rxjs';
import { DataKey, DataStorageService } from '../../services/data-storage/data-storage.service';
import { PlatformService } from '../../services/platform/platform.service';
import { LocalUser } from '../../services/users/users.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-bird-offer',
  templateUrl: './bird-offer.component.html',
  styleUrls: ['./bird-offer.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    DecimalPipe,
    LottieComponent,
    RouterLink,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BirdOfferComponent implements OnInit, OnChanges {
  @Input() location: string;
  protected days = 0;
  protected hours = 0;
  protected isClosing = false;
  protected isOpen = false;
  protected isToggled = false;
  protected minutes = 0;
  protected offerType: 'enrollment-promotion' | 'home-assistance-promotion' = 'home-assistance-promotion';
  protected seconds = 0;
  protected showOffer = true;
  private animation: AnimationItem;
  private hideBirdOffer: boolean;
  private targetDate = new Date('2025-02-28T19:19:19');
  private timerInterval: NodeJS.Timeout;

  constructor(
    private dataStorageService: DataStorageService,
    private platformService: PlatformService,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.offerType !== 'enrollment-promotion') {
      return;
    }
    if (changes['location'] && changes['location'].currentValue) {
      this.showOffer = !changes['location'].currentValue.startsWith('/enrollment');
    }
    const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
    this.showOffer = !localUser?.bankValidated;
  }

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      return;
    }
    this.startTimer();
    this.hideBirdOffer = !!(await firstValueFrom(this.dataStorageService.get<boolean>(DataKey.HideBirdOffer)));
  }

  protected close(): void {
    if (this.animation) {
      this.animation.setDirection(-1);
      this.animation.play();
    }
    this.isClosing = true;
    this.dataStorageService.set(DataKey.HideBirdOffer, true);
    this.hideBirdOffer = true;
    setTimeout(() => {
      this.isOpen = false;
      this.isClosing = false;
    }, 300);
  }

  protected onAnimationCreated(animation: AnimationItem): void {
    this.animation = animation;
    if (this.hideBirdOffer) {
      this.isOpen = false;
      return;
    }
    this.toggleExpand();
  }

  protected onMouseEnter(): void {
    if (!this.animation || this.isOpen) {
      return;
    }
    this.animation.setDirection(1);
    this.animation.play();
  }

  protected onMouseLeave(): void {
    if (!this.animation || this.isOpen) {
      return;
    }
    this.animation.setDirection(-1);
    this.animation.play();
  }

  protected toggleExpand(): void {
    if (!this.animation) {
      return;
    }
    this.isOpen ? this.close() : this.open();
    this.isToggled = !this.isToggled;
  }

  private calculateTimeRemaining(): void {
    const now = new Date().getTime();
    const timeLeft = this.targetDate.getTime() - now;
    if (timeLeft < 0) {
      clearInterval(this.timerInterval);
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.showOffer = false;
      return;
    }
    this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  }

  private open(): void {
    this.animation.setDirection(1);
    this.animation.play();
    this.isOpen = true;
    this.dataStorageService.set(DataKey.HideBirdOffer, false);
    this.hideBirdOffer = false;
  }

  private startTimer(): void {
    this.calculateTimeRemaining();
    this.timerInterval = setInterval(() => this.calculateTimeRemaining(), 1000);
  }
}