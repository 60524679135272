<p-sidebar [(visible)]="visible" [position]="isMobile ? 'bottom' : 'right'" showCloseIcon="false" blockScroll="true" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container" [class.hide]="sendingSimulation">
      <h2 class="title">{{ (simulationSent ? 'Simulation sent' : 'Send simulation') | transloco }}</h2>
      <div class="icon-container">
        <i class="icon icon-close" (click)="close()"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    @if (sendingSimulation) {
      <div class="progress-indicator-container">
        <p-progressSpinner />
        <p class="text">Procesando...</p>
      </div>
    } @else {
      <div class="content-container">
        <img class="illustration" src="{{ simulationSent ? '/assets/illustrations/success-check.svg' : '/assets/illustrations/whatsapp-logo-floating-on-phone.svg' }}" alt="{{ (simulationSent ? 'Image success check' : 'Image of WhatsApp logo on phone') | transloco }}" />
        <h3 class="title">{{ (simulationSent ? 'We send your simulation!' : 'We will send your simulation')  | transloco }}</h3>
        <p class="description">{{ (simulationSent ? 'Your simulation was sent by WhatsApp to the following phone number.' : 'Your simulation will be sent by WhatsApp to the following phone number:') | transloco }}</p>
        <p class="phone-number" data-private>{{ formattedPhoneNumber }}</p>
        <p class="terms-and-conditions">Al continuar estás aceptando nuestro<br><a href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" class="link" rel="noopener" target="_blank">Aviso de Privacidad</a> y <a href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" class="link" target="_blank" rel="noopener">Términos y Condiciones</a>. Este sitio está protegido por reCAPTCHA y aplican las <a class="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener">Políticas de Privacidad</a> y los <a class="link" href="https://policies.google.com/terms" target="_blank" rel="noopener">Términos de Servicio</a> de Google.</p>
        <p class="change-number-container">Lo ingresé mal. <span class="link" (click)="emitChangePhoneNumber()">Cambiar número.</span></p>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="footer">
    @if (!sendingSimulation) {
      @if (!simulationSent) {
        <div class="action-button-container" [class.hide]="sendingSimulation">
          <app-button innerId="btn-6AbMt-4d" label="Enviar simulación" styleClass="primary expand" (action)="send()" />
        </div>
      } @else {
        <div class="actions-buttons-container">
          <app-button innerId="btn-FKpElM70" label="Volver a simular" styleClass="secondary" (action)="emitSimulateAgain()" />
          <app-button innerId="btn-9Xa3DdpV" label="Ir a WhatsApp" styleClass="primary" href="{{ 'https://wa.me/' + platformPhoneNumber }}" target="_blank" rel="noopener" />
        </div>
      }
    }
  </ng-template>
</p-sidebar>