@if (!loadingProductSearch && !errorLoadingProductSearch) {
  <div class="product-search-headers" [class.empty-results]="!products.length">
    @if (products.length) {
      @if (query || categoryFilter) {
        <span class="title">{{ paginable.totalItems }} Resultados para:</span>
        <span class="query">
          @if (query) {
            {{ query }}
          } @else if (subcategoryFilter) {
            {{ categoriesLabels[subcategoryFilter] }}
          } @else if (categoryFilter) {
            {{ categoriesLabels[categoryFilter] }}
          }
        </span>
      } @else {
        <span class="title">{{ paginable.totalItems }} Resultados:</span>
      }
    } @else {
      @if (query || categoryFilter) {
        <span class="title">No se encontraron resultados para:</span>
        <span class="query">
          @if (query) {
            {{ query }}
          } @else if (subcategoryFilter) {
            {{ categoriesLabels[subcategoryFilter] }}
          } @else if (categoryFilter) {
            {{ categoriesLabels[categoryFilter] }}
          }
        </span>
      } @else {
        <span class="title">No se encontraron resultados.</span>
      }
    }
  </div>
}
<div class="product-search-container">
  <section class="product-search-section">
    <app-retryable-section class="button" [error]="errorLoadingProductSearch || errorLoadingMoreProducts" [loading]="loadingProductSearch" (retry)="getProductSearch()">
      @if (categoryFilter) {
        <div class="chips-categories-slider-container">
          @if (subcategoryFilter) {
            <div class="custom-button-clear" (click)="clearSubcategoryFilter()"><i class="icon-return icon"></i></div>
          } @else {
            <a class="custom-button-clear" routerLink="/ecommerce" ><i class="icon-return icon"></i></a>
          }
          <div class="separator"></div>
          <app-chips-slider class="slider" [items]="chipsCategoriesSlider" [selectedLabel]="subcategoryFilter ? categoriesLabels[subcategoryFilter] : null" (itemSelected)="goToSearch($event.filters)" />
        </div>
      }
      @if (hasPreviousItems) {
        <div class="see-previous-container">
          <app-button class="button" label="Ver anteriores" styleClass="secondary dark" [loading]="loadingPreviousProducts" (action)="getPrevious()" />
        </div>
      }
      <div class="products-container" [class.empty-results]="!products.length">
        @for (product of products; track $index) {
          <div class="product-card-container">
            <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]"></app-product-card>
          </div>
        }
      </div>
      @if (paginable && products.length) {
        <div class="see-more-container">
          <span class="message">Viendo {{ products.length }} de {{ paginable.totalItems }} productos</span>
          <p-progressBar [value]="percentageProductsLoaded" [showValue]="false" styleClass="progress-bar"></p-progressBar>
          @if (!paginable.done) {
            <app-button class="button" label="Ver más" styleClass="secondary dark" [loading]="loadingMoreProducts" (action)="getMore()" />
          }
        </div>
      }
    </app-retryable-section>
  </section>
  <section class="products-of-interest-section">
    <!-- TODO: Define filters for this section -->
    <app-products-carousel title="Ofertas del mes" />
  </section>
</div>
