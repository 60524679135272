import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, initializeAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { browserLocalPersistence } from '@firebase/auth';
import { provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import player from 'lottie-web';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { FirestoreService } from './services/firestore/firestore.service';
import { TranslocoHttpLoader } from './services/translation/transloco-http-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideClientHydration(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebaseConfig))),
    importProvidersFrom(provideAuth(() => getAuth())),
    importProvidersFrom(provideFirestore(() => getFirestore())),
    importProvidersFrom(provideStorage(() => getStorage())),
    importProvidersFrom(provideRemoteConfig(() => getRemoteConfig())),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.accountsServerFirebaseConfig, 'accountsServer'))),
    importProvidersFrom(provideFirestore(() => getFirestore(getApp('accountsServer')))),
    importProvidersFrom(provideAnalytics(() => getAnalytics())),
    {
      provide: 'accountsServerAuth',
      useFactory: () => initializeAuth(getApp('accountsServer'), { persistence: browserLocalPersistence }),
    },
    {
      provide: 'appFirestore',
      useFactory: () => new FirestoreService(),
    },
    {
      provide: 'accountsServerFirestore',
      useFactory: () => new FirestoreService('accountsServer'),
    },
    provideHttpClient(withFetch()),
    MessageService,
    // TODO: Delete when use firestore native
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: [environment.defaultLocale],
        defaultLang: environment.defaultLocale,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale(),
    provideLottieOptions({
      player: () => player,
    }),
    provideCacheableAnimationLoader(),
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    }
  ],
};
