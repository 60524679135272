import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { ProgressBarModule } from 'primeng/progressbar';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ChipsSliderComponent } from '../../../components/chips-slider/chips-slider.component';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { ProductsCarouselComponent } from '../../../components/products-carousel/products-carousel.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { SearchInputComponent } from '../../../components/search-input/search-input.component';
import { SearchSidebarComponent } from '../../../components/search-sidebar/search-sidebar.component';
import { constants } from '../../../constants';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { ProductsService } from '../../../services/products/products.service';
import { Paginable, SearchFilter } from '../../../services/search/search.service';
import { SeoService } from '../../../services/seo/seo.service';
import { areEqualArrays } from '../../../utils/array.utils';
import { areEqualObjects } from '../../../utils/object.utils';

interface Chip {
  filters: SearchFilter[];
  label: string;
}

@Component({
  selector: 'app-product-search',
  standalone: true,
  imports: [
    BadgeModule,
    ButtonComponent,
    ChipsSliderComponent,
    CommonModule,
    ProductCardComponent,
    ProductsCarouselComponent,
    ProgressBarModule,
    RetryableSectionComponent,
    RouterModule,
    SearchInputComponent,
    SearchSidebarComponent,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './product-search.page.html',
  styleUrl: './product-search.page.scss',
})
export class ProductSearchPage implements OnInit, OnDestroy {
  categoriesChipsTree: Record<string, Chip[]>;
  categoriesLabels: Record<string, string> = constants.ecommerce.categories.labels;
  categoryFilter: string | null = null;
  chipsCategoriesSlider: Chip[] = [];
  errorLoadingMoreProducts = false;
  errorLoadingPreviousProducts = false;
  errorLoadingProductSearch = false;
  favorites: Record<string, FavoriteItem> = {};
  filters: SearchFilter[] | null;
  hasPreviousItems = false;
  loadingMoreProducts = false;
  loadingPreviousProducts = false;
  loadingProductSearch = false;
  missingPreviousPage: number | null;
  order: { direction: 'asc' | 'desc'; field: string; } | null;
  page: number;
  paginable: Paginable<SearchEngineProduct>;
  percentageProductsLoaded = 0;
  products: SearchEngineProduct[] = [];
  query: string | null;
  subcategoryFilter: string | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private productsService: ProductsService,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.setCategoriesChipsTree();
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (params) => {
        const query = params['query'] ?? null;
        const filters = params['filters'] ? Object.keys(JSON.parse(params['filters'])).map((key) => JSON.parse(params['filters'])[key] as SearchFilter) : null;
        const order = params['order'] ? JSON.parse(params['order']) : null;
        this.page = params['page'] ? +params['page'] : 1;
        if (this.query !== query || !areEqualArrays(this.filters, filters) || !areEqualObjects(this.order, order)) {
          if (this.query !== query) {
            this.query = query;
          }
          if (!areEqualArrays(this.filters, filters)) {
            this.filters = filters;
          }
          if (!areEqualObjects(this.order, order)) {
            this.order = order;
          }
          this.getProductSearch();
        }
      }
    });
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  clearSubcategoryFilter(): void {
    this.subcategoryFilter = null;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/ecommerce/search'], { queryParams: { filters: JSON.stringify([{ field: 'categories', operator: 'in', value: [this.categoryFilter] }]) } });
  }

  getMore(): void {
    if (this.loadingMoreProducts) {
      return;
    }
    this.loadingMoreProducts = true;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.paginable.getMore()
      .then((search) => {
        this.paginable = search;
        this.page = this.page + 1;
        this.products = [ ...this.products, ...search.data ];
        this.updateProductsValues();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['/ecommerce/search'], {
          queryParams: {
            page: this.page,
            ...(this.query && { query: this.query }),
            ...(this.filters && { filters: JSON.stringify(this.filters) }),
            ...(this.order && { order: JSON.stringify(this.order) }),
          }
        });
      })
      .catch((error) => {
        this.errorReportingService.log('ProductSearchPage.getMore()', 'paginable-more', error);
        this.errorLoadingMoreProducts = true;
      })
      .finally(() => this.loadingMoreProducts = false);
  }

  getPrevious(): void {
    if (this.loadingPreviousProducts) {
      return;
    }
    this.loadingPreviousProducts = true;
    this.missingPreviousPage = this.page - Math.ceil(this.products.length / this.paginable.size);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.productsService.search(this.query, {
      pagination: {
        page: this.missingPreviousPage,
      },
      filters: this.filters,
      order: this.order,
    })
      .then((search) => {
        this.missingPreviousPage = this.missingPreviousPage === 1 ? this.missingPreviousPage - 1 : null;
        this.products = [ ...search.data, ...this.products ];
        this.updateProductsValues();
      })
      .catch((error) => {
        this.errorReportingService.log('ProductSearchPage.getPrevious()', 'get-product-search', error);
        this.errorLoadingPreviousProducts = true;
      })
      .finally(() => this.loadingPreviousProducts = false);
  }

  getProductSearch(): void {
    this.setCategoryFilter();
    this.loadingProductSearch = true;
    this.errorLoadingProductSearch = false;
    this.products = [];
    if (this.query) {
      this.categoryFilter = null;
      this.subcategoryFilter = null;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.productsService.search(this.query, {
      pagination: {
        page: this.page,
      },
      filters: this.filters,
      order: this.order,
    })
      .then((search) => {
        this.paginable = search;
        this.products = [ ...this.products, ...search.data ];
        this.updateProductsValues();
      })
      .catch((error) => {
        this.products = [];
        this.errorLoadingProductSearch = true;
        this.errorReportingService.log('ProductSearchPage.getProductSearch()', 'get-product-search', error);
      })
      .finally(() => this.loadingProductSearch = false);
  }

  goToSearch(filters: SearchFilter[]): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/ecommerce/search'], { queryParams: { filters: JSON.stringify(filters) } });
  }

  private updateProductsValues(): void {
    this.hasPreviousItems = this.paginable && this.paginable.totalItems > 0 && this.paginable.totalItems > this.paginable.size && this.paginable.totalItems !== this.products.length && this.products.length < this.page * this.paginable.size;
    this.percentageProductsLoaded = this.products.length / this.paginable.totalItems * 100;
  }

  private initialize(): void {
    this.favoritesService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (favorite) => {
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
      },
      error: (error) => this.errorReportingService.log('ProductSearchPage.initialize()', 'get-all-favorites', error),
    });
  }

  private setCategoryFilter(): void {
    const [value] = this.filters?.find((filter) => filter.field === 'categories')?.value as string[] ?? [];
    this.chipsCategoriesSlider = this.categoriesChipsTree[value] ?? Object.values(this.categoriesChipsTree).find((subcategories) => subcategories.some((subcategory) => subcategory.label === this.categoriesLabels[value])) ?? [];
    const isSubcategoryFiltered = !this.categoriesChipsTree[value];
    if (isSubcategoryFiltered) {
      this.categoryFilter = Object.keys(constants.ecommerce.categories.tree).find((category) => constants.ecommerce.categories.tree[category].includes(value)) ?? null;
      this.subcategoryFilter = value;
      return;
    }
    this.categoryFilter = value;
    this.subcategoryFilter = null;
  }

  private setCategoriesChipsTree(): void {
    this.categoriesChipsTree = Object.entries(constants.ecommerce.categories.tree).reduce((tree, [category, subcategories]) => {
      tree[category] = subcategories.map((subcategory) => ({
        filters: [{ field: 'categories', operator: 'in', value: [subcategory] }],
        label: this.categoriesLabels[subcategory],
      }));
      return tree;
    }, {} as Record<string, Chip[]>);
  }
}
