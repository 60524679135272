<section class="complaints-and-returns-section">
  <div class="left-section">
    <app-retryable-section [error]="errorLoadingInitialData" [loading]="loadingInitialData" (retry)="initialize()">
      @if (user && user.enrollmentStatus === 'done') {
        @if (showSuccessScreen) {
          <div class="success-container">
            <img src="assets/images/success-img.svg" alt="Imagen de éxito">
            <h2 class="title">¡Solicitud enviada!</h2>
            <span class="text">Tu solicitud fue enviada y pronto nos contactaremos contigo.</span>
            <app-button label="Regresar al inicio" class="back" styleClass="primary" routerLink="/" />
          </div>
        } @else {
          <div class="title-container">
            <h4 class="title" data-private>Hola, {{ userName }} 👋</h4>
            <p class="subtitle">Lamentamos que estés teniendo inconvenientes. ¡Te ayudaremos a solucionarlo!</p>
          </div>
          <p class="text-center">Primero confirma tus datos de contacto para seguimiento</p>
          <form [formGroup]="form" class="form-container">
            <div class="input-container">
              <label for="email">Correo electrónico</label>
              <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" [class.input]="form.controls.email.enabled" data-private />
              <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">Ingresa un correo válido.</small>
            </div>
            <div class="input-container">
              <label for="phone-number">Número telefónico</label>
              <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" [class.disabled]="form.controls.phoneNumber.disabled" [class.input-mask]="form.controls.phoneNumber.enabled" data-private />
              <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
            </div>
            <a class="change-contact-information" routerLink="/private-site/profile/my-personal-data" [queryParams]="{ redirectTo: '/complaints' }">Modificar datos de contacto</a>
            <p class="text-center">Ahora ayúdanos con más información sobre la situación</p>
            <div class="input-container">
              <label for="service">Servicio</label>
              <p-dropdown id="service" class="service-input" placeholder="Selecciona un servicio..." (onChange)="loadOrders()" [class.active]="isActive(form.controls.service)" formControlName="service" [options]="services" optionValue="value" optionLabel="label" />
            </div>
            <app-retryable-section [error]="errorLoadingOrders" [loading]="loadingOrders" (retry)="loadOrders()">
              @if (form.controls.service.value === 'ecommerce' && (ecommerceOrders.length > 0)) {
                <form [formGroup]="formEcommerce">
                  <div class="input-container">
                    <label class="multiselect-label">Producto</label>
                    <p-multiSelect [options]="orders" showHeader="false" group="true" formControlName="selectedEcommerceOrder" optionValue="value" placeholder="Selecciona productos para tu solicitud" [maxSelectedLabels]="1" (onChange)="handleSelectedEcommerceOrder($event)">
                      <ng-template let-selectedItems pTemplate="selectedItems">
                        <span>
                          @if (!selectedItems || selectedItems.length === 0) {
                            Selecciona productos para tu solicitud
                          } @else if (selectedItems?.length === 1) {
                            {{ selectedItems[0].item.brand }} - {{ selectedItems[0].item.name }}
                          } @else {
                            Orden: {{ selectedItems[0].order.id }} - {{ selectedItems.length }} productos
                          }
                        </span>
                      </ng-template>
                      <ng-template let-order pTemplate="item">
                        <img class="img" src="{{ order.value.item.thumbnail }}" alt="Foto del producto" />
                        <div class="item-container">
                          <span class="brand">{{ order.value.item.brand }}</span>
                          <span class="name">{{ order.value.item.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </div>
                  <div class="request-container">
                    <div class="input-container">
                      <label for="request-type">Tipo de solicitud</label>
                      <p-dropdown id="request-type" class="service-input" placeholder="Tipo de solicitud" (onChange)="handleRequestTypeChange($event)" formControlName="requestType" [options]="requestOptions" />
                    </div>
                    <div class="input-container">
                      <label for="request-detail">Detalle de solicitud</label>
                      <p-dropdown id="request-detail" class="service-input" placeholder="Detalle de solicitud" formControlName="requestDetail" [options]="requestDetailOptions" />
                    </div>
                  </div>
                </form>
              } @else if (form.controls.service.value === 'moving' && movingOrders.length) {
                <form [formGroup]="formMoving">
                  <div class="input-container">
                    <label for="service">Compra</label>
                    <p-dropdown id="movingPurchase" class="service-input" placeholder="Selecciona una mudanza" formControlName="purchase" [options]="movingOrders">
                      <ng-template let-selectedItem pTemplate="selectedItem">
                        <div class="moving-container">
                          <span class="brand">
                            @if (!selectedItem) {
                              Selecciona tu mudanza
                            } @else {
                              {{ selectedItem.id }} - {{ selectedItem.items.length }} items, Dia de mudanza: {{ selectedItem.movingDate | date: 'dd/MM/yy' }}
                            }
                          </span>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <p>ID orden: {{ item.id }}</p>
                        <p><strong>{{ item.additionalData.serviceType ?? 'Servicio por seleccionar' }}</strong></p>
                        <p>{{ item.items.length }} items</p>
                        <p>Dia de mudanza: {{ item.movingDate | date: 'dd/MM/yy' }}</p>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="input-container">
                    <label for="moving-request-type">Tipo de solicitud</label>
                    <p-dropdown id="moving-request-type" class="service-input" placeholder="Selecciona tu solicitud" formControlName="requestType" [options]="suggestedApplications" optionLabel="label" optionValue="value" />
                  </div>
                </form>
              } @else if (form.controls.service.value === 'warehouse' && warehouseOrders.length) {
                <form [formGroup]="formWarehouse">
                  <div class="input-container">
                    <label for="service">Compra</label>
                    <p-dropdown id="warehouse-purchase" class="service-input" placeholder="Selecciona una bodega" formControlName="purchase" [options]="warehouseOrders">
                      <ng-template let-selectedItem pTemplate="selectedItem">
                        <div class="warehouse-container">
                          <span class="brand">
                            @if (!selectedItem) {
                              Selecciona tu bodega
                            } @else {
                              {{ selectedItem.id }}
                              @if (selectedItem.additionalData?.['volume']) {
                                - {{ selectedItem.additionalData?.['volume'] }} ㎥
                              }
                            }
                          </span>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <p>ID orden: {{ item.id }}</p>
                        @if (item.additionalData?.['volume']) {
                          <p><strong>{{ item.additionalData?.['volume'] }} ㎥</strong></p>
                        }
                        <p>Inicio: {{ item.startAt | date: 'dd/MM/yyyy' }}</p>
                        <p>Vence: {{ item.finishAt | date: 'dd/MM/yyyy' }}</p>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="input-container">
                    <label for="warehouse-request-type">Tipo de solicitud</label>
                    <p-dropdown id="warehouse-request-type" class="service-input" placeholder="Selecciona tu solicitud" formControlName="requestType" [options]="suggestedApplications" optionLabel="label" optionValue="value" />
                  </div>
                </form>
              } @else if (form.controls.service.value === 'home-assistance' && homeAssistanceOrdersDetails.length) {
                <form [formGroup]="formHomeAssistance">
                  <div class="input-container">
                    <label for="service">Compra</label>
                    <p-dropdown id="home-assistance-purchase" class="service-input" placeholder="Selecciona una asistencia" formControlName="purchase" [options]="homeAssistanceOrdersDetails">
                      <ng-template let-selectedItem pTemplate="selectedItem">
                        <div class="home-assistance-container">
                          @if (!selectedItem) {
                            <span class="brand">Selecciona tu asistencia</span>
                          } @else {
                            <span>{{ selectedItem.plan.name }} (ID: #{{ selectedItem.id }}) - {{ selectedItem.duration === '1-month' ? ' Membresía mensual' : selectedItem.duration === '6-months' ? 'Membresía semestral' : selectedItem.duration === '12-months' ? 'Membresía anual' : selectedItem.duration }}</span>
                          }
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <p><strong>{{ item.plan.name }}</strong> (ID: #{{ item.id }})</p>
                        <p>{{ item.duration === '1-month' ? ' Membresía mensual' : item.duration === '6-months' ? 'Membresía semestral' : item.duration === '12-months' ? 'Membresía anual' : item.duration }}</p>
                        <p>Cobertura hasta: {{ item.finishAt | date: 'dd/MM/yy' }}</p>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="input-container">
                    <label for="home-assistance-request-type">Tipo de solicitud</label>
                    <p-dropdown id="home-assistance-request-type" class="service-input" placeholder="Selecciona tu solicitud" formControlName="requestType" [options]="suggestedHomeAssistanceApplications" optionLabel="label" optionValue="value" />
                  </div>
                </form>
              } @else if (form.controls.service.value === 'remodeling' && remodelingOrders.length) {
                <form [formGroup]="formRemodeling">
                  <div class="input-container">
                    <label for="service">Compra</label>
                    <p-dropdown id="remodeling-purchase" class="service-input" placeholder="Selecciona una remodelación" formControlName="purchase" [options]="remodelingOrders">
                      <ng-template let-selectedItem pTemplate="selectedItem">
                        <div class="remodeling-container">
                          <span class="brand">
                            @if (!selectedItem) {
                              Selecciona tu remodelación
                            } @else {
                              ID obra: {{ selectedItem.id }} / {{ selectedItem.additionalData.service | transloco }}
                            }
                          </span>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <p>ID obra: {{ item.id }}</p>
                        <p>{{ item.additionalData.service | transloco }}</p>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </form>
              }
            </app-retryable-section>
            @if (form.controls.service.value && !errorLoadingOrders && !loadingOrders) {
              <div class="describe-application">
                <p class="text-center">Describe tu solicitud detalladamente</p>
                <div class="input-container">
                  <textarea class="message" id="message" rows="3" [class.active]="isActive(form.controls.message)" [maxLength]="maxWords" pInputText formControlName="message" (input)="handleTextAreaEvent($event)"></textarea>
                  <div class="word-count">{{ wordCount }}/{{ maxWords }}</div>
                </div>
              </div>
            }
          </form>
          <div class="button-container">
            <app-button label="Enviar formulario" class="send" styleClass="primary expand" [disabled]="validateForms" [loading]="sendingForm" (action)="onSubmit()" />
          </div>
        }
      } @else {
        <div class="not-logged">
          <div class="content">
            <div class="container">
              <h4 class="title">Hola 👋</h4>
              <p class="subtitle">Lamentamos que estés teniendo inconvenientes. ¡Te ayudaremos a solucionarlo!</p>
              <p class="login-call">Para brindarte un servicio de ayuda personalizado es necesario que ingreses a tu cuenta Mundo Hogar</p>
              <app-button label="Ingresar" routerLink="/enrollment/sign-in" [queryParams]="{ redirectTo: '/complaints-and-returns' }" />
            </div>
            <span class="help-account">Si tienes problemas para acceder tu cuenta Mundo Hogar o no tienes una, <a class="link" routerLink="/contact">presiona aquí</a></span>
          </div>
        </div>
      }
    </app-retryable-section>
  </div>
  <div class="right-section">
    <div class="text-container">
      <h2 class="title">Estamos para ayudarte</h2>
      <p class="subtitle">Tu tranquilidad nos importa</p>
    </div>
  </div>
</section>