import { Injectable } from '@angular/core';
import {
  AddShippingAddressEvent,
  AddToFavoritesEvent,
  AddToShoppingCartEvent,
  AnalyticsService,
  BeginCheckoutEvent,
  GenerateLeadEvent,
  HomeAssistanceEvent,
  MortgageSimulatorEvent,
  MovingEvent,
  ProjectSimulatorEvent,
  PurchaseEvent,
  RemodelingEvent,
  RemoveFromShoppingCartEvent,
  SearchEvent,
  SignInEvent,
  SignUpEvent,
  UserCreationEvent,
  ViewItemEvent,
  ViewShoppingCartEvent,
  WarehouseEvent,
} from './analytics.service';

@Injectable()
export class AnalyticsServiceMock implements AnalyticsService {
  logAddShippingAddressEvent(data: AddShippingAddressEvent): void {
    console.log('Analytic event add_shipping_info', data);
  }

  logAddToFavoritesEvent(data: AddToFavoritesEvent): void {
    console.log('Analytic event add_to_wishlist', data);
  }

  logAddToShoppingCartEvent(data: AddToShoppingCartEvent): void {
    console.log('Analytic event add_to_cart', data);
  }

  logBeginCheckoutEvent(data: BeginCheckoutEvent): void {
    console.log('Analytic event begin_checkout', data);
  }

  logGenerateLeadEvent(data: GenerateLeadEvent): void {
    console.log('Analytic event generate_lead', data);
  }

  logHomeAssistanceEvent(data: HomeAssistanceEvent): void {
    console.log('Analytic event home_assistance', data);
  }

  logMortgageSimulatorEvent(data: MortgageSimulatorEvent): void {
    console.log('Analytic event mortgage_simulator', data);
  }

  logMovingEvent(data: MovingEvent): void {
    console.log('Analytic event moving', data);
  }

  logProjectSimulatorEvent(data: ProjectSimulatorEvent): void {
    console.log('Analytic event project_simulator', data);
  }

  logPurchaseEvent(data: PurchaseEvent): void {
    console.log('Analytic event purchase', data);
  }

  logRemodelingEvent(data: RemodelingEvent): void {
    console.log('Analytic event remodeling', data);
  }

  logRemoveFromShoppingCartEvent(data: RemoveFromShoppingCartEvent): void {
    console.log('Analytic event remove_from_cart', data);
  }

  logSearchEvent(data: SearchEvent): void {
    console.log('Analytic event search', data);
  }

  logSignInEvent(data: SignInEvent): void {
    console.log('Analytic event login', data);
  }

  logSignUpEvent(data: SignUpEvent): void {
    console.log('Analytic event sign_up', data);
  }

  logUserCreationEvent(data: UserCreationEvent): void {
    console.log('Analytic event user_creation', data);
  }

  logViewItemEvent(data: ViewItemEvent): void {
    console.log('Analytic event view_item', data);
  }

  logViewShoppingCartEvent(data: ViewShoppingCartEvent): void {
    console.log('Analytic event view_cart', data);
  }

  logWarehouseEvent(data: WarehouseEvent): void {
    console.log('Analytic event warehouse', data);
  }
}
