@if (showOffer) {
  @if (offerType === 'enrollment-promotion') {
    <div class="offer-container">
      <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="toggleExpand()" class="lottie-container">
        <div class="lottie-container">
          <div class="circle-shadow"></div>
          <ng-lottie class="image" [options]="{ autoplay: false, loop: false, path: '/assets/animations/lottie-bird.json' }" (animationCreated)="onAnimationCreated($event)">
          </ng-lottie>
        </div>
      </div>
      <div class="background-box" [class.expanded]="isOpen && !isClosing" [class.fade-out]="isClosing">
        <div class="close" (click)="toggleExpand()">
          <i class="icon icon-close"></i>
        </div>
        <div class="title-container" [class.fade-in-up]="isToggled">
          <div class="upper-title">
            <h4 class="title">Crea tu cuenta y gana</h4>
          </div>
          <h2 class="promo">Hasta 20,000 MXN</h2>
          <p class="subtitle">Para utilizar en Mundo Hogar</p>
        </div>
        <div class="body-content" [class.fade-in-up]="isToggled">
          <div class="item">
            <img class="box" src="/assets/illustrations/account-award/big-prize.svg" alt="Imagen de premio mayor">
            <p class="subtitle">20,000 MXN</p>
          </div>
          <div class="item">
            <img class="box" src="/assets/illustrations/account-award/middle-prize.svg" alt="Imagen de premio mediano">
            <p class="subtitle">10,000 MXN</p>
          </div>
          <div class="item">
            <img class="box" src="/assets/illustrations/account-award/small-prize.svg" alt="Imagen de premio menor">
            <p class="subtitle">2,000 MXN</p>
          </div>
        </div>
        <div class="countdown-container" [class.fade-in-up]="isToggled">
          <div class="countdown-box">
            <div class="countdown-value">{{ days | number: '2.0-0' }}</div>
            <div class="countdown-label">Días</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ hours | number: '2.0-0' }}</div>
            <div class="countdown-label">Horas</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ minutes | number: '2.0-0' }}</div>
            <div class="countdown-label">Min.</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ seconds | number: '2.0-0' }}</div>
            <div class="countdown-label">Seg.</div>
          </div>
        </div>
        <div class="buttons-container" [class.fade-in-up]="isToggled">
          <app-button label="Registrarse y participar" routerLink="/enrollment/sign-up" [queryParams]="{ offer: 'true' }" />
          <app-button class="legal" label="Bases legales" styleClass="tertiary dark" />
        </div>
      </div>
    </div>
  } @else if (offerType === 'home-assistance-promotion') {
    <div class="offer-container hogar-offer">
      <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="toggleExpand()" class="lottie-container">
        <div class="lottie-container">
          <div class="circle-shadow"></div>
          <ng-lottie class="image" [options]="{ autoplay: false, loop: false, path: '/assets/animations/lottie-bird.json' }" (animationCreated)="onAnimationCreated($event)">
          </ng-lottie>
        </div>
      </div>
      <div class="background-box" [class.expanded]="isOpen && !isClosing" [class.fade-out]="isClosing">
        <div class="close" (click)="toggleExpand()">
          <i class="icon icon-close"></i>
        </div>
        <div class="title-container" [class.fade-in-up]="isToggled">
          <div class="upper-title">
            <h4 class="title">Contrata tu plan de asistencia</h4>
          </div>
          <h2 class="promo">Desde 49.00 MXN al mes</h2>
        </div>
        <div class="body-content" [class.fade-in-up]="isToggled">
          <div class="item">
            <img class="box" src="/assets/images/home-plumbing.svg" alt="Imagen de plomería">
            <p class="subtitle">Plomería</p>
          </div>
          <div class="item">
            <img class="box" src="/assets/images/home-veterinary.svg" alt="Imagen de veterinario">
            <p class="subtitle">Veterinario</p>
          </div>
          <div class="item">
            <img class="box" src="/assets/images/many-more.svg" alt="Imagen de mucho más">
            <p class="subtitle">¡Y Mucho más!</p>
          </div>
        </div>
        <p class="limited-time" [class.fade-in-up]="isToggled">Oferta por tiempo limitado</p>
        <div class="countdown-container" [class.fade-in-up]="isToggled">
          <div class="countdown-box">
            <div class="countdown-value">{{ days | number: '2.0-0' }}</div>
            <div class="countdown-label">Días</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ hours | number: '2.0-0' }}</div>
            <div class="countdown-label">Horas</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ minutes | number: '2.0-0' }}</div>
            <div class="countdown-label">Min.</div>
          </div>
          <div class="countdown-box">
            <div class="countdown-value">{{ seconds | number: '2.0-0' }}</div>
            <div class="countdown-label">Seg.</div>
          </div>
        </div>
        <div class="buttons-container" [class.fade-in-up]="isToggled">
          <app-button class="plan-button" label="Elegir plan" routerLink="/home-assistance" [queryParams]="{ offer: 'true' }" (action)="close()" />
          <div class="offer-owner">
            <i class="icon icon-circle-checkmark"></i>
            <p class="text-container">
              <span class="disclaimer">Servicio entregado por</span>
              <img src="/assets/images/ike-logo.svg" class="logo" alt="Logo iké" />
            </p>
          </div>
        </div>
      </div>
    </div>
  }
}

