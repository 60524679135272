import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonComponent } from '../../components/button/button.component';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';

@Component({
  selector: 'app-benefits',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CardModule,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TooltipModule,
  ],
  templateUrl: './benefits.page.html',
  styleUrl: './benefits.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsPage implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('benefits');
    }
  }
}
