<p-sidebar class="manage-addresses-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()" (onShow)="show()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">{{ (isFormVisible ? 'Add new address' : 'Shipping address') | transloco }}</span>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <app-retryable-section [loading]="loadingAddresses" [error]="errorLoadingAddresses" (retry)="getAddresses()">
      <div class="manage-addresses-container">
        @if (isFormVisible) {
          <div class="form-container">
            <form [formGroup]="form" class="form">
              <div class="input-container">
                <label class="label" for="alias">Alias*</label>
                <input class="input" pInputText id="alias" [formControl]="form.controls.alias" [class.active]="isActive(this.form.controls.alias)" maxlength="32" aria-describedby="alias-help" data-private />
                <small id="alias-help" [class.hidden]="!this.form.controls.alias.touched || !this.form.controls.alias.errors" class="p-error input-error">Ingresa un alias válido.</small>
              </div>
              <div class="input-container">
                <label class="label" for="postal-code">Código postal*</label>
                <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" maxlength="5" [class.active]="isActive(this.form.controls.postalCode)" aria-describedby="postal-code-help" data-private />
                <small id="postal-code-help" [class.hidden]="(!this.form.controls.postalCode.touched || !this.form.controls.postalCode.errors) && !invalidPostalCode" class="p-error input-error">Ingresa un código postal válido.</small>
              </div>
              @if (postalCodeLocation) {
                <div class="input-container">
                  <label class="label" for="state">Estado*</label>
                  <input class="input" pInputText id="state" [formControl]="form.controls.state" [class.active]="isActive(this.form.controls.state)" maxlength="64" aria-describedby="state-help" data-private />
                  <small id="state-help" [class.hidden]="!this.form.controls.state.touched || !this.form.controls.state.errors" class="p-error input-error">Ingresa un estado válido.</small>
                </div>
                <div class="input-container">
                  <label class="label" for="municipality">Municipio*</label>
                  <p-dropdown id="municipality" [formControl]="form.controls.municipality" [class.active]="isActive(this.form.controls.municipality)" [options]="postalCodeLocation.municipalities" placeholder="Selecciona un municipio" aria-describedby="municipality-help" data-private />
                  <small id="municipality-help" [class.hidden]="!this.form.controls.municipality.touched || !this.form.controls.municipality.errors" class="p-error input-error">Selecciona un municipio.</small>
                </div>
                <div class="input-container">
                  <label class="label" for="province">Colonia*</label>
                  <p-dropdown id="province" [formControl]="form.controls.province" [class.active]="isActive(this.form.controls.province)" [options]="postalCodeLocation.settlements" placeholder="Selecciona una colonia" aria-describedby="province-help" data-private />
                  <small id="province-help" [class.hidden]="!this.form.controls.province.touched || !this.form.controls.province.errors" class="p-error input-error">Selecciona una colonia.</small>
                </div>
              }
              <div class="input-container">
                <label class="label" for="street">Calle*</label>
                <input class="input" pInputText id="street" [formControl]="form.controls.street" [class.active]="isActive(this.form.controls.street)" maxlength="64" aria-describedby="street-help" data-private />
                <small id="street-help" [class.hidden]="!this.form.controls.street.touched || !this.form.controls.street.errors" class="p-error input-error">Ingresa una calle válida.</small>
              </div>
              <div class="input-container">
                <label class="label" for="street-number">Número de la calle*</label>
                <input class="input" pInputText id="street-number" [formControl]="form.controls.streetNumber" [class.active]="isActive(this.form.controls.streetNumber)" maxlength="16" aria-describedby="street-number-help" data-private />
                <small id="street-number-help" [class.hidden]="!this.form.controls.streetNumber.touched || !this.form.controls.streetNumber.errors" class="p-error input-error">Ingresa un número de la calle válido.</small>
              </div>
              <div class="input-container">
                <label class="label" for="street-internal-number">Número interior</label>
                <input class="input" pInputText id="street-internal-number" [formControl]="form.controls.streetInternalNumber" [class.active]="isActive(this.form.controls.streetInternalNumber)" maxlength="16" aria-describedby="street-internal-number-help" data-private />
                <small id="street-internal-number-help" [class.hidden]="!this.form.controls.streetInternalNumber.touched || !this.form.controls.streetInternalNumber.errors" class="p-error input-error">Ingresa un número de la calle válido.</small>
              </div>
              <div class="input-container">
                <label class="label" for="reference">Referencias</label>
                <input class="input" pInputText id="reference" [formControl]="form.controls.reference" [class.active]="isActive(this.form.controls.reference)" maxlength="128" aria-describedby="reference-help" />
                <small id="reference-help" [class.hidden]="!this.form.controls.reference.touched || !this.form.controls.reference.errors" class="p-error input-error">Ingresa una referencia válida.</small>
              </div>
              <div class="required-fields">
                <p>Campos obligatorios*</p>
              </div>
            </form>
          </div>
        } @else {
          <div class="addresses-container">
            <div class="addresses-info-container">
              @for (address of addresses; track $index) {
                <div class="address-container" [class.selected]="address.id === selectedAddressId" (click)="selectAddress(address.id)">
                  @if (address.id === selectedAddressId) {
                    <p class="selected-address">Dirección de envío actual</p>
                  }
                  <div class="title-container">
                    <span class="alias" data-private>{{ address.alias }}</span>
                    <!-- TODO: Uncomment when edit was implemented -->
                    <!-- <p class="edit">Editar</p>-->
                  </div>
                  <span class="address" data-private>
                    {{ address.street }},
                    {{ address.streetNumber }},
                    {{ address.streetInternalNumber ? (address.streetInternalNumber + ',') : '' }}
                    {{ address.province }},
                    {{ address.municipality }},
                    {{ address.state }},
                    {{ address.country }}
                </span>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </app-retryable-section>
  </ng-template>
  <ng-template pTemplate="footer">
    @if ((!errorLoadingAddresses && !loadingAddresses) || isFormVisible) {
      <div class="action-button-container" [class.form]="isFormVisible">
        @if (isFormVisible) {
          <app-button class="button" label="Agregar dirección" styleClass="primary" [disabled]="form.invalid || loadingSpinner" (action)="createAddress()" [loading]="loadingSpinner" />
        } @else {
          <app-button class="button" label="Agregar nueva dirección" styleClass="secondary" (action)="formVisible()" />
        }
      </div>
    }
  </ng-template>
</p-sidebar>