<app-retryable-section [error]="errorLoading" [loading]="loading" (retry)="initialize()">
  <section class="contact-container">
    <div class="left-section">
      <h1 class="section-title">
        <i class="icon icon-arrow-left" (click)="goBack()"></i>
        <span>Formulario de ayuda</span>
      </h1>
      @if (!showSuccessScreen) {
        <div class="not-logged">
          <div class="content">
            <div class="container">
              <h4 class="title">Hola 👋</h4>
              <p class="subtitle">Lamentamos que estés teniendo inconvenientes.<br>¡Te ayudaremos a solucionarlo!</p>
              @if (!user) {
                <span class="help-account">Si tienes cuenta Mundo Hogar, <a class="link" (click)="goToSignIn()">ingresa aquí</a>.</span>
              }
            </div>
          </div>
        </div>
        <form [formGroup]="form" class="form-container">
          <div class="input-container">
            <label for="name">Nombre</label>
            <input id="name" type="text" pInputText formControlName="name" aria-describedby="name-help" [class.disabled]="form.controls.name.disabled" [class.active]="isActive(form.controls.name)" data-private />
            <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">Ingresa un nombre válido.</small>
          </div>
          <div class="input-container">
            <label for="father-last-name">Apellido paterno</label>
            <input id="father-last-name" type="text" pInputText formControlName="fatherLastName" aria-describedby="father-last-name-help" [class.disabled]="form.controls.fatherLastName.disabled" [class.active]="isActive(form.controls.fatherLastName)" data-private />
            <small id="father-last-name-help" [class.hidden]="!form.controls.fatherLastName.touched || !form.controls.fatherLastName.errors" class="p-error input-error">Ingresa un apellido paterno válido.</small>
          </div>
          <div class="input-container">
            <label for="mother-last-name">Apellido materno</label>
            <input id="mother-last-name" type="text" pInputText formControlName="motherLastName" aria-describedby="mother-last-name-help" [class.disabled]="form.controls.motherLastName.disabled" [class.active]="isActive(form.controls.motherLastName)" data-private />
            <small id="mother-last-name-help" [class.hidden]="!form.controls.motherLastName.touched || !form.controls.motherLastName.errors" class="p-error input-error">Ingresa un apellido materno válido.</small>
          </div>
          <div class="input-container">
            @if (user?.emailValidated) {
              <div class="email-container">
                <label for="email" class="label">Correo electrónico</label>
                <div class="verified-container" [class.disabled]="form.controls.email.disabled">
                  <i class="icon icon-checkmark"></i>
                  <small class="email-verified" [class.check]="user?.emailValidated">Verificado</small>
                </div>
              </div>
            } @else {
              <label for="email">Correo electrónico</label>
            }
            <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" [class.active]="isActive(form.controls.email)" data-private />
            <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">Ingresa un correo válido.</small>
          </div>
          <div class="input-container">
            <label for="phone-number">Número telefónico</label>
            <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" [class.disabled]="form.controls.phoneNumber.disabled" [class.active]="isActive(form.controls.phoneNumber)" data-private />
            <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
          </div>
          <div class="input-container">
            <label for="message">Mensaje</label>
            <textarea id="message" rows="3" pInputText formControlName="message" [class.active]="isActive(form.controls.message)"></textarea>
          </div>
          <p class="privacy-policy-text">Este sitio está protegido por reCAPTCHA y aplican las <a class="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener">Políticas de Privacidad</a> y los <a class="link" href="https://policies.google.com/terms" target="_blank" rel="noopener">Términos de Servicio</a> de Google.</p>
          <app-button label="Enviar formulario" class="button" styleClass="primary expand" [disabled]="!form.valid" [loading]="sendingForm" (action)="onSubmit()" />
        </form>
      } @else {
        <div class="success-container">
          <img src="assets/images/success-img.svg" alt="Tu formulario ha sido enviado" />
          <h1 class="title">Tu formulario ha sido enviado</h1>
          <span class="text">Nos comunicaremos contigo prontamente para ayudarte en este proceso.</span>
          <app-button label="Volver al home" styleClass="secondary" routerLink="/" />
        </div>
      }
    </div>
    <div class="right-section">
      <div class="text-container">
        <h2 class="title">Mundo Hogar</h2>
        <p class="subtitle">Estamos en contacto contigo para apoyarte en tus sueños</p>
      </div>
    </div>
  </section>
</app-retryable-section>