<section class="ecommerce-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" routerLink="/private-site/my-purchases/{{ orderId }}" [queryParams]="{ itemId: orderItemId }"></i>
    }
    <h2 class="title">Detalle de compra</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (order) {
      <div class="total-container">
        <div class="purchase-date-and-order-id">{{ order.createdAt | date: 'dd/MM/yyyy' }} | #{{ order.id }}</div>
        <div class="purchase-breakdown">
          <span class="label">Productos</span>
          <span class="value">{{ order.subtotal | currencyFormatter: order.currency }} {{ order.currency }}</span>
        </div>
        <div class="purchase-breakdown">
          <span class="label">Envío</span>
          <span class="value">{{ order.shippingCost | currencyFormatter: order.currency }} {{ order.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</div>
        </div>
      </div>
      <app-purchase-payment-details [paymentIntentIds]="[order.paymentIntentId]" />
      <div class="shipping-container">
        <h2 class="title">Detalles del envío</h2>
        <div class="address-container">
          <div class="icon-container">
            <i class="icon-truck"></i>
          </div>
          <div class="info" data-private>
            <span>{{ order.shippingAddress.street }} {{ order.shippingAddress.streetNumber }}</span>
            <span>{{ order.shippingAddress.province }}, {{ order.shippingAddress.municipality }}</span>
          </div>
        </div>
        <div class="products-container">
          <p-accordion class="accordion-purchase-items">
            <p-accordionTab class="accordion-items" header="{{ 'Purchase items (X items)' | transloco: { quantity: order.items.length } }}" iconPos="end">
              @for (item of order.items; track item) {
                <div class="product-container">
                  <img class="image" [src]="item.thumbnail" alt="Imagen del producto" loading="lazy" />
                  <div class="info">
                    <span class="above">{{ item.name }}</span>
                    <span class="under">{{ item.price | currencyFormatter: order.currency }} {{ order.currency }} | {{ item.quantity }} u.</span>
                  </div>
                </div>
              }
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    }
  </app-retryable-section>
</section>