<section class="main-container">
  <div class="remodeling-status-container">
    <div class="title-container">
      <i class="icon icon-arrow-left" routerLink="/private-site/my-remodelings"></i>
      <h2 class="title">Estado de la remodelación</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (order) {
        <div class="remodeling-status-info">
          @if (order.status === 'lead-created') {
            <span class="status active">Solicitud recibida</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">Hemos recibido tus datos. Pronto te llamaremos para recopilar más información de tu proyecto.</span>
          } @else if(order.status === 'pending-budget') {
            <span class="status active">Preparando tu presupuesto</span>
            <span class="description">{{ order.additionalData['opportunityName'] ?? '' }}</span>
            <span class="status-description">Estamos preparando un buen presupuesto para tí.</span>
          } @else if (order.status === 'pending-contract') {
            <span class="status active">Gestiona tu firma</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">En proceso de firma contrato con tu franquiciado.</span>
          } @else if (order.status === 'pending') {
            @if (!order.paid || order.pendingChargeIntentId) {
              <span class="status active">Todo listo, paga tu presupuesto</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">¡Tu presupuesto fue enviado! Págalo para confirmar y agendar el inicio de la obra.</span>
              @if (chargeIntentDetail) {
                <div class="charge-intent-container">
                  <div class="charge-intent-info">
                    <div class="charge-intent-detail">
                      <span class="detail">Proyecto de remodelación</span>
                      <span class="amount">{{ chargeIntentDetail.amount | currencyFormatter: chargeIntentDetail.currency }} {{ chargeIntentDetail.currency }}</span>
                    </div>
                    <div class="charge-intent-detail">
                      <span class="detail">Impuestos</span>
                      <span class="amount">{{ chargeIntentDetail.tax | currencyFormatter: chargeIntentDetail.currency }} {{ chargeIntentDetail.currency }}</span>
                    </div>
                  </div>
                  <div class="charge-intent-detail total">
                    <span class="detail">Total incl. IVA</span>
                    <span class="amount">{{ chargeIntentDetail.total | currencyFormatter: chargeIntentDetail.currency }} <span class="currency">{{ chargeIntentDetail.currency }}</span></span>
                  </div>
                </div>
              }
              <div class="secondary-button-container">
                <app-button styleClass="secondary expand" label="Pagar presupuesto" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [order.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
              </div>
            } @else if (!order.contractSigned) {
              <span class="status active">Ahora solo falta tu firma</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">¡Tu presupuesto se encuentra pagado! Fírmalo para confirmar y agendar el inicio de la obra.</span>
            }
          } @else if (order.status === 'waiting') {
            <span class="status active">¡Todo listo!</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">Tu proyecto está agendado para que comience el {{ order.startAt | date: 'dd/MM/yyyy' }}</span>
          } @else if (order.status === 'in-progress') {
            @if (!order.pendingChargeIntentId) {
              <span class="status active">Proyecto en obra</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">Tu proyecto ha comenzando el {{ order.startAt | date: 'dd/MM/yyyy' }}</span>
            } @else {
              <span class="status pending">Pago pendiente</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">Tienes un pago pendiente</span>
              @if (chargeIntentDetail) {
                <div class="charge-intent-container">
                  <div class="charge-intent-info">
                    <div class="charge-intent-detail">
                      <span class="detail">Proyecto de remodelación</span>
                      <span class="amount">{{ chargeIntentDetail.amount | currencyFormatter: chargeIntentDetail.currency }} {{ chargeIntentDetail.currency }}</span>
                    </div>
                    <div class="charge-intent-detail">
                      <span class="detail">Impuestos</span>
                      <span class="amount">{{ chargeIntentDetail.tax | currencyFormatter: chargeIntentDetail.currency }} {{ chargeIntentDetail.currency }}</span>
                    </div>
                  </div>
                  <div class="charge-intent-detail total">
                    <span class="detail">Total incl. IVA</span>
                    <span class="amount">{{ chargeIntentDetail.total | currencyFormatter: chargeIntentDetail.currency }} <span class="currency">{{ chargeIntentDetail.currency }}</span></span>
                  </div>
                </div>
              }
              <div class="secondary-button-container">
                <app-button styleClass="secondary expand" label="Pagar presupuesto" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [order.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
              </div>
            }
          } @else if (order.status === 'done') {
            <span class="status">Proyecto finalizado</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">Tu proyecto ha finalizado el {{ order.endAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Ayúdanos a mejorar la experiencia Mundo Hogar.</span>
            <!-- TODO: Enable when implementing page to evaluate experience
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Evaluar experiencia" href="/" />
            </div>
            -->
          } @else if (order.status === 'cancelled') {
            <span class="status pending">Proyecto cancelado</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">Lamentamos cancelar tu proyecto.</span>
            <span class="status-description">Por favor, coméntanos como podemos mejorar el servicio.</span>
            <!-- TODO: Enable when implementing page to evaluate experience
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Evaluar experiencia" href="/" />
            </div>
            -->
          }
        </div>
        @if (order && order.projectManager) {
          <div class="franchisee-data-container">
            <h4 class="title">Datos de tu franquicia asignada</h4>
            @if (order.projectManager.name) {
              <span class="info">Nombre: {{ order.projectManager.name }}</span>
            }
            @if (order.projectManager.email) {
              <span class="info">Correo electrónico: {{ order.projectManager.email }}</span>
            }
            @if (order.projectManager.phoneNumber) {
              <span class="info">Número telefónico: {{ order.projectManager.phoneNumber }}</span>
            }
          </div>
        }
        <div class="help-links-container">
          <h4 class="title">Ayuda con esta remodelación</h4>
          <a routerLink="/complaints-and-returns" [queryParams]="{
            orderId: order.id,
            service: 'remodeling',
          }" target="_blank" class="link">Obtener ayuda</a>
          <a href="https://ayuda.mundohogar.com.mx/es/" target="_blank" class="link">Información sobre Garantías</a>
          <!-- TODO: Enable when implementing page to evaluate experience
          <a href="" target="_blank" class="link">Escribir una opinión sobre el servicio</a>
          --->
          <a href="tel:5255416628" target="_blank" class="link" rel="noopener">Llamar a central Aquí Tu Remodelación (+52) 554 166 28 21</a>
        </div>
        @if (showRemodelingPurchaseDetail) {
          <div class="remodeling-details-navigation-container" routerLink="/private-site/my-remodelings/{{ order.id }}/details">
            <span class="label">Detalles de la compra</span>
            <i class="icon icon-arrow-right"></i>
          </div>
        }
      }
    </app-retryable-section>
  </div>
  @if (showRemodelingPurchaseDetail) {
    <div class="remodeling-purchase-container">
      <app-remodeling-purchase-details [order]="order" [showBackButton]="false" />
    </div>
  }
</section>